import {
  ACTION_1,
  ACTIVATE_FORM,
  ADD_ITEM_TO_CART,
  ADD_PROTEGE,
  ALERT_HIDE,
  ALERT_SHOW,
  ALERT_TIMEOUT,
  BUY_CART_ITEM,
  CHECK_AUTH_DONE,
  CHECK_AUTH_FAILED,
  CHECK_AUTH_START,
  DEACTIVATE_FORM,
  DONE,
  DUPLICATE_FORM,
  EDIT_ITEM_CART,
  EDIT_PROTEGE,
  FAILED,
  FETCH_ACTIVATED_KITS,
  FETCH_ACTIVE_FORMS,
  FETCH_ALL_MY_TESTS,
  FETCH_ALL_TERMS,
  FETCH_CART_ITEMS,
  FETCH_CONFIG,
  FETCH_CONFIG_GLOBAL_DONE,
  FETCH_FILLED_FORMS_DONE,
  FETCH_FILLED_FORMS_EXTENDED,
  FETCH_FILLED_TESTS,
  FETCH_FORM_DATA_DONE,
  FETCH_FORM_DONE,
  FETCH_FORMS_DONE,
  FETCH_HAS_ANY_ORDERS,
  FETCH_OLD_FORM_EDITIONS,
  FETCH_ORDERS_IN_PROGRESS,
  FETCH_ORG_NAMES_DONE,
  FETCH_ORG_USERS_DONE,
  FETCH_ORG_USERS_FAILED,
  FETCH_ORG_USERS_START,
  FETCH_ORGANIZATION_DONE,
  FETCH_PRICE_LIST,
  FETCH_PROTEGES,
  FETCH_SCHEDULED_FORMS,
  JOIN_ORGANIZATION_DONE,
  JOIN_ORGANIZATION_FAILED,
  JOIN_ORGANIZATION_START,
  LOGIN_DONE,
  LOGIN_FAILED,
  LOGIN_START,
  LOGOUT_DONE,
  PAYMENT_STATUS,
  PAYMENT_VERIFY,
  PURCHASE_ITEMS_PAYMENT,
  READ_FONT_SIZE,
  READ_GLOBAL_THEME,
  RECOVER_PASSWORD_DONE,
  RECOVER_PASSWORD_FAILED,
  RECOVER_PASSWORD_START,
  REGISTER_ORGANIZATION_DONE,
  REGISTER_ORGANIZATION_FAILED,
  REGISTER_ORGANIZATION_START,
  REMOVE_CART_ITEM_DONE,
  REMOVE_FORM_DONE,
  REMOVE_ITEM_FROM_CART,
  REMOVE_PROTEGE,
  REMOVE_TERM_DONE,
  SET_APP_BAR_TITLE,
  SET_FONT_SIZE,
  START,
  TOGGLE_GLOBAL_THEME,
  TOGGLE_REQUESTED_SHARING_BY_OWNER,
  UPDATE_ENTITLED_USERS,
  UPDATE_FORM_DATA_IS_SHARED,
  UPDATE_ORDER_IN_PROGRESS,
  UPDATE_ORDER_STATUS,
  X_AUTH_TOKEN_KEY,
} from "../../constants";
import {
  ANONIMIZATION_MY,
  FETCH_MY,
  REMOVED_AVATAR,
  UPDATE_MY,
} from "../person/actions";
import {
  FETCH_TERMS_ORGANIZATION,
  UPDATE_MY_ORGANIZATION,
} from "../organization/actions";

const initialState = {
  organization: null,
  loggedInUser: null,
  authToken: null,
  creatingOrganization: false,
  joiningOrganization: false,
  joinedOrganization: undefined,
  loggingIn: undefined,
  loggedIn: undefined,
  errorMessage: "w",
  orgUsers: [],
  proteges: [],
  protegesFetching: false,
  protegeAdded: false,
  protegeEdited: false,
  protegeRemoved: false,
  orgForms: [],
  scheduledForms: [],
  orgOldFromEditions: [],
  orgReports: [],
  joiningComplete: false,
  formData: null,
  filledForms: {},
  filledFormsExtended: {},
  recoveringPassword: false,
  orgUsersFetching: false,
  orgUsersFetched: false,
  orgUsersFailed: false,
  alerts: [],
  my: null,
  checkingAuth: true,
  roles: ["GUEST"],
  activeForms: [],
  configGlobal: null,
  config: null,
  cart: [],
  cartItems: [],
  filledTests: [],
  priceList: {},
  ordersInProgress: [],
  hasAnyOrders: null,
  terms: [],
  fetchingOrdersInProgress: false,
  fetchedOrdersInProgress: false,
  allMyTests: [],
  allMyTestsFetching: false,
  activatedKits: [],
  appBarTitle: "",
  paymentStatus: "",
  paymentVerify: null,
  paymentStarting: false,
  globalTheme: "light",
  fontSize: "normal",
};

function rootReducer(state = { ...initialState }, action) {
  if (action.type === ACTION_1) {
    return state;
  }

  if (action.type === REGISTER_ORGANIZATION_START) {
    return {
      ...state,
      creatingOrganization: true,
    };
  }

  if (action.type === REGISTER_ORGANIZATION_FAILED) {
    return {
      ...state,
      creatingOrganization: false,
    };
  }

  if (action.type === REGISTER_ORGANIZATION_DONE) {
    return {
      ...state,
      organization: action.payload,
      creatingOrganization: false,
    };
  }

  if (action.type === JOIN_ORGANIZATION_START) {
    return {
      ...state,
      joiningOrganization: true,
      joiningComplete: false,
      joinedOrganization: null,
    };
  }

  if (action.type === JOIN_ORGANIZATION_FAILED) {
    return {
      ...state,
      joiningOrganization: false,
      joinedOrganization: false,
    };
  }

  if (action.type === JOIN_ORGANIZATION_DONE) {
    return {
      ...state,
      organization: action.payload,
      joiningComplete: true,
    };
  }

  if (action.type === LOGIN_START) {
    return {
      ...state,
      authToken: null,
      loggingIn: true,
      loggedIn: false,
    };
  }

  if (action.type === LOGIN_DONE) {
    sessionStorage.setItem(X_AUTH_TOKEN_KEY, action.payload.data.token);

    return {
      ...state,
      authToken: action.payload.data.token,
      loggingIn: false,
      loggedIn: true,
      my: action.payload.my,
      roles: action.payload.my.user.authorities.split(","),
    };
  }

  if (action.type === LOGIN_FAILED) {
    return {
      ...state,
      authToken: null,
      loggingIn: false,
      loggedIn: false,
      errorMessage: action.errorMessage,
    };
  }

  if (action.type === LOGOUT_DONE) {
    sessionStorage.removeItem(X_AUTH_TOKEN_KEY);

    return {
      ...initialState,
    };
  }

  if (action.type === CHECK_AUTH_START) {
    return {
      ...state,
      authToken: null,
      loggedIn: false,
      loggingIn: true,
      checkingAuth: true,
    };
  }

  if (action.type === CHECK_AUTH_DONE) {
    sessionStorage.setItem(X_AUTH_TOKEN_KEY, action.payload.token);

    return {
      ...state,
      authToken: action.payload.token,
      loggedIn: true,
      loggingIn: false,
      checkingAuth: false,
      my: action.payload.my,
      roles: action.payload.my.user.authorities.split(","),
    };
  }

  if (action.type === CHECK_AUTH_FAILED) {
    return {
      ...state,
      authToken: null,
      loggedIn: false,
      loggingIn: false,
      checkingAuth: false,
    };
  }

  if (action.type === FETCH_ORG_USERS_DONE) {
    return {
      ...state,
      orgUsersFetching: false,
      orgUsersFailed: false,
      orgUsersFetched: true,
      orgUsers: action.payload.users,
    };
  }
  if (action.type === FETCH_ORG_USERS_START) {
    return {
      ...state,
      orgUsersFetching: true,
      orgUsersFailed: false,
      orgUsersFetched: false,
    };
  }
  if (action.type === FETCH_ORG_USERS_FAILED) {
    return {
      ...state,
      orgUsersFetching: false,
      orgUsersFailed: true,
      orgUsersFetched: false,
    };
  }

  if (action.type === FETCH_ORG_NAMES_DONE) {
    return {
      ...state,
      orgNamesFetching: false,
      orgNamesFailed: false,
      orgNamesFetched: true,
      orgNames: action.payload.orgNames,
    };
  }

  if (action.type === FETCH_ORGANIZATION_DONE) {
    return {
      ...state,
      organization: action.payload[0],
    };
  }

  if (action.type === FETCH_CONFIG_GLOBAL_DONE) {
    return {
      ...state,
      configGlobal: {
        ...action.payload,
      },
    };
  }

  if (action.type === FETCH_FORMS_DONE) {
    return {
      ...state,
      orgForms: action.payload
        .filter(({ status }) => status !== "DELETED")
        .sort((a, b) => b.id - a.id),
    };
  }

  if (action.type === FETCH_SCHEDULED_FORMS + DONE) {
    return {
      ...state,
      scheduledForms: action.payload,
    };
  }

  if (action.type === REMOVE_FORM_DONE) {
    return {
      ...state,
      orgForms: state.orgForms.filter(({ id }) => id !== action.payload),
    };
  }

  if (action.type === FETCH_FORM_DONE) {
    const exists = state.orgForms.find(({ id }) => id === action.payload.id);

    if (exists) {
      return {
        ...state,
        orgForms: state.orgForms.map((f) => {
          if (f.id === action.payload.id) {
            return action.payload;
          }
          return f;
        }),
      };
    } else {
      return {
        ...state,
        orgForms: [action.payload],
      };
    }
  }

  if (action.type === ACTIVATE_FORM + DONE) {
    const exists = state.orgForms.find(({ id }) => id === action.payload.id);

    if (exists) {
      return {
        ...state,
        orgForms: state.orgForms.map((f) => {
          if (f.id === action.payload.id) {
            return action.payload;
          }
          return f;
        }),
      };
    } else {
      return {
        ...state,
        orgForms: [action.payload],
      };
    }
  }

  if (action.type === FETCH_FORM_DATA_DONE) {
    return {
      ...state,
      formData: action.payload,
    };
  }

  if (action.type === FETCH_FILLED_FORMS_DONE) {
    return {
      ...state,
      filledForms: {
        ...state.filledForms,
        [action.payload.formId]: action.payload.data,
      },
    };
  }

  if (action.type === FETCH_FILLED_FORMS_EXTENDED + DONE) {
    const exists = state.orgForms.find(({ id }) => id === action.payload.id);

    if (exists) {
      return {
        ...state,
        orgForms: state.orgForms.map((f) => {
          if (f.id === action.payload.id) {
            return action.payload;
          }
          return f;
        }),
        filledFormsExtended: {
          ...state.filledFormsExtended,
          [action.payload.formId]: action.payload.data,
        },
      };
    } else {
      return {
        ...state,
        orgForms: [action.payload],
        filledFormsExtended: {
          ...state.filledFormsExtended,
          [action.payload.formId]: action.payload.data,
        },
      };
    }
  }

  if (
    action.type === RECOVER_PASSWORD_DONE ||
    action.type === RECOVER_PASSWORD_FAILED
  ) {
    return {
      ...state,
      recoveringPassword: false,
    };
  }

  if (action.type === RECOVER_PASSWORD_START) {
    return {
      ...state,
      recoveringPassword: true,
    };
  }

  if (action.type === ALERT_SHOW) {
    return {
      ...state,
      alerts: [...state.alerts, { ...action.payload, id: +new Date() }],
    };
  }

  if (action.type === ALERT_HIDE || action.type === ALERT_TIMEOUT) {
    return {
      ...state,
      alerts: state.alerts.filter((alert) => alert.id !== action.payload),
    };
  }

  if (action.type === FETCH_MY + DONE) {
    return {
      ...state,
      my: action.payload,
    };
  }

  if (action.type === UPDATE_MY + DONE) {
    return {
      ...state,
      my: {
        ...state.my,
        firstName: action.payload.firstName,
        surname: action.payload.surname,
        gender: action.payload.gender,
        yearOfBirth: action.payload.yearOfBirth,
        address: action.payload.address,
        ...(action.payload.avatarPath && {
          avatarPath: action.payload.avatarPath,
        }),
        // avatarPath: action.payload.avatarPath,
      },
    };
  }

  if (action.type === REMOVED_AVATAR + DONE) {
    return {
      ...state,
      my: {
        ...state.my,
        firstName: action.payload.firstName,
        surname: action.payload.surname,
        gender: action.payload.gender,
        yearOfBirth: action.payload.yearOfBirth,
        address: action.payload.address,
        avatarPath: action.payload.avatarPath,
      },
    };
  }

  if (action.type === ANONIMIZATION_MY + DONE) {
    return {
      ...state,
      my: action.payload,
    };
  }

  if (action.type === FETCH_ACTIVE_FORMS + DONE) {
    return {
      ...state,
      activeForms: action.payload,
    };
  }

  if (action.type === FETCH_ACTIVE_FORMS + DONE) {
    return {
      ...state,
      activeForms: action.payload,
    };
  }

  if (action.type === UPDATE_ENTITLED_USERS + DONE) {
    return {
      ...state,
      orgForms: state.orgForms.map((form) => {
        if (form.id === action.payload.id) {
          return action.payload;
        }
        return form;
      }),
    };
  }

  if (action.type === DUPLICATE_FORM + DONE) {
    return {
      ...state,
      orgForms: [...state.orgForms, action.payload],
    };
  }

  if (action.type === DEACTIVATE_FORM + DONE) {
    return {
      ...state,
      orgForms: state.orgForms.map((form) => {
        if (form.id === action.payload.id) {
          return action.payload;
        }
        return form;
      }),
    };
  }

  if (action.type === FETCH_OLD_FORM_EDITIONS + DONE) {
    const firstForm = action.payload[0];
    return {
      ...state,
      orgOldFromEditions: [
        ...state.orgOldFromEditions.filter((form) => {
          return !firstForm || firstForm.bag.id !== form.bag.id;
        }),
        ...action.payload,
      ].sort((a, b) => b.id - a.id), // newer first
    };
  }

  if (action.type === UPDATE_MY_ORGANIZATION + DONE) {
    return {
      ...state,
      organization: action.payload,
    };
  }

  if (action.type === ADD_ITEM_TO_CART + DONE) {
    return {
      ...state,
      cartItems: [...state.cartItems, action.payload],
    };
  }

  if (action.type === EDIT_ITEM_CART + DONE) {
    return {
      ...state,
      cartItems: state.cartItems.map((cartItem) => {
        if (cartItem.id === action.payload.id) {
          return action.payload;
        }
        return cartItem;
      }),
    };
  }

  if (action.type === REMOVE_ITEM_FROM_CART + DONE) {
    return {
      ...state,
      cartItems: state.cartItems.filter(({ id }) => id !== action.payload),
    };
  }

  // if (action.type === ADD_ITEM_TO_CART + DONE) {
  //   return {
  //     ...state,
  //     cart: [...state.cart, action.payload],
  //   };
  // }

  if (action.type === FETCH_CART_ITEMS + DONE) {
    return {
      ...state,
      cartItems: action.payload.sort((a, b) => b.id - a.id),
    };
  }

  if (action.type === REMOVE_CART_ITEM_DONE) {
    return {
      ...state,
      cartItems: state.cartItems.filter(({ id }) => id !== action.payload),
    };
  }

  if (action.type === FETCH_PRICE_LIST + DONE) {
    const products = action.payload.products || {};
    return {
      ...state,
      priceList: Object.keys(products).reduce((all, current) => {
        return {
          ...all,
          currency: action.payload.currency,
          [current]: {
            price: products[current].prices[0].unitPrice,
            vat: products[current].vatRate,
            currency: action.payload.currency,
          },
        };
      }, {}),
    };
  }

  if (action.type === FETCH_ORDERS_IN_PROGRESS + START) {
    return {
      ...state,
      ordersInProgress: [],
      fetchingOrdersInProgress: true,
      fetchedOrdersInProgress: false,
    };
  }

  if (action.type === FETCH_ORDERS_IN_PROGRESS + DONE) {
    return {
      ...state,
      ordersInProgress: action.payload.content,
      ordersInProgressTotalElements: action.payload.pagination.totalElements,
      fetchingOrdersInProgress: false,
      fetchedOrdersInProgress: true,
    };
  }

  if (action.type === FETCH_HAS_ANY_ORDERS + DONE) {
    return {
      ...state,
      hasAnyOrders: action.payload,
      // fetchingOrdersInProgress: false,
    };
  }

  if (action.type === UPDATE_ORDER_IN_PROGRESS + DONE) {
    return {
      ...state,
      ordersInProgress: action.payload,
      fetchingOrdersInProgress: false,
    };
  }

  if (action.type === FETCH_ALL_MY_TESTS + DONE) {
    return {
      ...state,
      cartItems: state.cartItems.filter(({ id }) => id !== action.payload),
    };
  }

  if (action.type === FETCH_FILLED_TESTS + DONE) {
    return {
      ...state,
      filledTests: action.payload,
    };
  }

  if (action.type === FETCH_FILLED_TESTS + FAILED) {
    return {
      ...state,
      filledTests: [],
    };
  }

  if (action.type === BUY_CART_ITEM + DONE) {
    const boughtCartItemId = action.payload;
    return {
      ...state,
      cartItems: state.cartItems.filter((c) => c.id !== boughtCartItemId),
      allMyTests: action.payload,
      allMyTestsFetching: true,
    };
  }

  if (action.type === BUY_CART_ITEM + FAILED) {
    return {
      ...state,
      allMyTestsFetching: false,
    };
  }

  if (action.type === BUY_CART_ITEM + START) {
    return {
      ...state,
      allMyTestsFetching: true,
    };
  }

  if (action.type === FETCH_ACTIVATED_KITS + DONE) {
    return {
      ...state,
      activatedKits: action.payload,
    };
  }

  if (action.type === TOGGLE_REQUESTED_SHARING_BY_OWNER + DONE) {
    return {
      ...state,
      allMyTests: state.allMyTests.map((test) => {
        if (test.id === action.payload.id) {
          return {
            ...test,
            isSharingWithOwnerRequested:
              action.payload.isSharingWithOwnerRequested,
          };
        }
        return test;
      }),
      filledTests: state.filledTests.map((test) => {
        if (test.id === action.payload.id) {
          return {
            ...test,
            isSharingWithOwnerRequested:
              action.payload.isSharingWithOwnerRequested,
          };
        }
        return test;
      }),
    };
  }

  if (action.type === UPDATE_FORM_DATA_IS_SHARED + DONE) {
    return {
      ...state,
      filledTests: state.filledTests.map((data) => {
        if (data.id === action.payload.id) {
          return {
            ...data,
            isShared: action.payload.isShared,
          };
        }
        return data;
      }),
    };
  }

  if (action.type === SET_APP_BAR_TITLE) {
    return {
      ...state,
      appBarTitle: action.payload.title,
    };
  }

  if (action.type === FETCH_TERMS_ORGANIZATION + DONE) {
    return {
      ...state,
      terms: action.payload,
    };
  }

  if (action.type === FETCH_ALL_TERMS + DONE) {
    return {
      ...state,
      terms: action.payload,
    };
  }

  if (action.type === REMOVE_TERM_DONE) {
    return {
      ...state,
      terms: action.payload,
    };
  }

  if (action.type === REMOVE_PROTEGE + START) {
    return {
      ...state,
      protegeRemoved: false,
    };
  }

  if (action.type === REMOVE_PROTEGE + DONE) {
    return {
      ...state,
      protegeRemoved: true,
    };
  }
  if (action.type === REMOVE_PROTEGE + FAILED) {
    return {
      ...state,
      protegeRemoved: true,
    };
  }

  if (action.type === FETCH_PROTEGES + START) {
    return {
      ...state,
      protegesFetching: true,
    };
  }

  if (action.type === FETCH_PROTEGES + FAILED) {
    return {
      ...state,
      protegesFetching: false,
    };
  }

  if (action.type === FETCH_PROTEGES + DONE) {
    return {
      ...state,
      proteges: action.payload.content,
      protegesAll: action.payload.pagination.totalElements,
      protegesFetching: false,
    };
  }

  if (action.type === ADD_PROTEGE + START) {
    return {
      ...state,
      protegeAdded: false,
    };
  }
  if (action.type === ADD_PROTEGE + DONE) {
    return {
      ...state,
      protegeAdded: true,
    };
  }
  if (action.type === ADD_PROTEGE + FAILED) {
    return {
      ...state,
      protegeAdded: true,
    };
  }

  if (action.type === EDIT_PROTEGE + START) {
    return {
      ...state,
      protegeEdited: false,
    };
  }
  if (action.type === EDIT_PROTEGE + DONE) {
    return {
      ...state,
      protegeEdited: true,
    };
  }
  if (action.type === EDIT_PROTEGE + FAILED) {
    return {
      ...state,
      protegeEdited: true,
    };
  }
  if (action.type === PAYMENT_STATUS + DONE) {
    return {
      ...state,
      paymentStatus: action.payload.session.status,
    };
  }
  if (action.type === PURCHASE_ITEMS_PAYMENT + START) {
    return {
      ...state,
      paymentStarting: true,
    };
  }
  if (action.type === PAYMENT_VERIFY + DONE) {
    return {
      ...state,
      paymentVerify: action.httpCode,
    };
  }
  if (action.type === TOGGLE_GLOBAL_THEME + DONE) {
    let newTheme;

    if (state.globalTheme === "light") {
      newTheme = "high-contrast";
    } else {
      newTheme = "light";
    }

    localStorage.setItem("globalTheme", newTheme);

    return {
      ...state,
      globalTheme: newTheme,
    };
  }
  if (action.type === READ_GLOBAL_THEME) {
    let newTheme = localStorage.getItem("globalTheme") || "light";
    return {
      ...state,
      globalTheme: newTheme,
    };
  }

  if (action.type === READ_FONT_SIZE) {
    let newFontSize = localStorage.getItem("fontSize") || "normal";
    return {
      ...state,
      fontSize: newFontSize,
    };
  }

  if (action.type === SET_FONT_SIZE + DONE) {
    localStorage.setItem("fontSize", action.payload);
    return {
      ...state,
      fontSize: action.payload,
    };
  }

  if (action.type === UPDATE_ORDER_STATUS) {
    return {
      ...state,
      ordersInProgress: state.ordersInProgress.map((order) => {
        if (order.orderId === action.payload.orderId) {
          return {
            ...order,
            status: action.payload.status,
          };
        }

        return order;
      }),
    };
  }

  if (action.type === FETCH_CONFIG + DONE) {
    return {
      ...state,
      config: action.payload,
    };
  }

  return state;
}

export default rootReducer;
