import React, { useEffect, useState } from "react";
import { NavLink, Redirect, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Can from "../../components/can";
import OrderSummary from "../../components/order-summary";
import { Box, Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Button from "@material-ui/core/Button";
import {
  buyCartItem,
  fetchCartItems,
  fetchPriceList,
  paymentCancel,
  purchaseItemPayment,
  purchaseItemsPayment,
  removeCartItem,
} from "../../redux/shopping-cart/actions";
import { alertAdd, editItemCart } from "../../redux/actions";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBarTitle from "../../components/app-bar-title";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Loader from "../../components/loader";
import { ReactComponent as TrashIcon } from "../../assets/trash.svg";
import Link from "@material-ui/core/Link";

const TYPE_TO_NAME_MAP = {
  SMELLTASTE_TEST: "Węch i smak",
  SMELL_TEST: "Węch",
  TASTE_TEST: "Smak",
};

const MuiNavLink = withStyles((theme) => ({
  root: {
    color: theme.palette.anchor.primary,
    underline: "always",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
}))(Link);

const MuiHeaderTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    padding: "16px 16px 0px 16px",
    fontSize: theme.typography.leftMenuLabeL,
    color: theme.palette.text.alternative3,
    letterSpacing: "0.24px",
  },
}))(TableCell);

const MuiBodyTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    // padding: "8px 16px 0px 16px",
    color: theme.palette.text.alternative2,
    fontSize: theme.typography.text,
  },
}))(TableCell);
const NO_ID_TO_REMOVE = -1;

const AgreeButton = withStyles((theme) => ({
  root: {
    color: theme.palette.background.default,
    background: theme.palette.text.alternative,
    variant: "outlined",
    paddingRight: "40px",
    paddingLeft: "40px",
    "&:hover": {
      color: theme.palette.text.primary2,
    },
  },
}))(Button);

const DisagreeButton = withStyles((theme) => ({
  root: {
    color: theme.palette.background.default,
    background: theme.palette.text.primary,
    variant: "outlined",
    paddingRight: "40px",
    paddingLeft: "40px",
    "&:hover": {
      color: theme.palette.text.primary2,
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    overflowX: "auto",
  },
  margin: {
    margin: theme.spacing(1),
  },
  tableRow: {
    backgroundColor: "#ff726f !important",
  },
  mainGrid: {
    height: "86vh",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  contentGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  itemGrid: {
    display: "flex",
    justifyContent: "center",
  },
  label: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: theme.typography.text,
    lineHeight: "20px",
    color: theme.palette.text.primary,
  },
  // button: {
  //   background: "#0000FF",
  //   borderRadius: "4px",
  // },
  buttonOrder: {
    // background: "#476BFF",
    color: theme.palette.text.alternative2,
    borderColor: theme.palette.text.alternative2,
    letterSpacing: "0.45px",
  },
  newOrderButton: {
    // backgroundColor: "#0000FF",
    borderRadius: "4px",
  },
  gridTable: {
    margin: "12px auto",
  },
  bodyCell: {
    letterSpacing: "0.33px",
    font: "normal normal normal 22px/27px Open Sans",
  },
  orderButtonGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "12px 8px",
    boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
    position: "sticky",
    top: "6rem",
    [theme.breakpoints.down("sm")]: {
      top: "6rem",
    },
    background: theme.palette.background.default,
    zIndex: "100",
  },
  container: {
    maxWidth: "3000px",
    minWidth: "300px",
    padding: "0px",
  },
  orderTable: {
    padding: theme.spacing(2),
  },
  buttonBase: {
    "& .MuiAccordionSummary-expandIcon": {
      cursor: "default",
      pointerEvents: "none",
    },
  },
  buttonBase1: {
    "& .MuiAccordionSummary-expandIcon": {
      transform: "rotate(270deg)",
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(0deg)",
    },
  },
  title: {
    color: theme.palette.text.alternative2,
  },
  titleDetails: {
    color: theme.palette.text.alternative2,
    fontSize: theme.typography.header,
  },
  icon: {
    fill: theme.palette.checkIcon.primary,
    cursor: "pointer",
  },
  link: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.text.alternative2,
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  spanText: {
    color: theme.palette.text.primary,
  },
}));

const ShoppingCart = ({
  cartItems,
  buyCartItem,
  removeCartItem,
  purchaseItemsPayment,
  alertAdd,
  history,
  paymentStarting,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const location = useLocation();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState(null);
  const [expandedOrders, setExpandedOrders] = useState([]);

  const priceList = useSelector((s) => s.priceList || null);

  const my = useSelector((s) => s.my);

  useEffect(() => {
    if (Object.keys(priceList).length === 0) {
      dispatch(fetchPriceList());
    }
  }, [priceList]);

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const [idToRemove, setIdToRemove] = useState(NO_ID_TO_REMOVE);
  const [openCartItemRemovingPanel, setOpenCartItemRemovingPanel] = useState(
    false
  );

  useEffect(() => {
    return () => {
      if (
        history.action === "POP" &&
        history.location.pathname === "/test-kit"
      ) {
        if (
          window.confirm(
            "Dane formularze po przejściu do strony będą utracone. Kliknij OK, jeśli akceptujesz ten fakt."
          )
        ) {
          history.replace({
            pathname: history.location.pathname,
            state: null,
          });
        } else {
          // history.replace({
          //   pathname: "/shopping-cart",
          // });
          history.push("/shopping-cart");
        }
      }
    };
  }, [history]);

  const askToRemove = (protegeId) => {
    setIdToRemove(protegeId);
  };

  useEffect(() => {
    dispatch(fetchCartItems());
  }, []);

  useEffect(() => {
    if (expandedOrders.length === 0 && cartItems.length > 0) {
      const tmpCartItems = cartItems?.sort((a, b) => b.id - a.id);
      if (
        location.state &&
        typeof dataNotProvided(
          tmpCartItems.find((cartItem) => cartItem.id === location.state.id)
        )?.isProvided != "undefined"
          ? !dataNotProvided(
              tmpCartItems.find((cartItem) => cartItem.id === location.state.id)
            )?.isProvided
          : false
      ) {
        setExpandedOrders([{ id: location.state.id }]);
      } else if (
        !location.state &&
        tmpCartItems[0] &&
        typeof dataNotProvided(tmpCartItems[0])?.isProvided != "undefined"
          ? !dataNotProvided(tmpCartItems[0])?.isProvided
          : false
      ) {
        setExpandedOrders([{ id: tmpCartItems[0].id }]);
      }
    }
  }, [cartItems]);

  const dataNotProvided = (item) => {
    if (item?.deliveryInfo && item?.billingInfo) {
      return {
        isProvided: !(
          item.deliveryInfo.name &&
          item.deliveryInfo.street &&
          item.deliveryInfo.zipCode &&
          item.deliveryInfo.city &&
          item.billingInfo.name &&
          item.billingInfo.street &&
          item.billingInfo.zipCode &&
          item.billingInfo.city &&
          item.billingInfo.taxId
        ),
        message: "Uzupełnij adres dostawy lub dane do faktury",
      };
    } else if (item?.deliveryInfo) {
      return {
        isProvided: !(
          item.deliveryInfo.name &&
          item.deliveryInfo.street &&
          item.deliveryInfo.zipCode &&
          item.deliveryInfo.city
        ),
        message: "Uzupełnij adres dostawy",
      };
    } else if (!item?.deliveryInfo) {
      return {
        isProvided: true,
        message: "Uzupełnij adres dostawy",
      };
    } else if (item?.billingInfo) {
      return {
        isProvided: !(
          item.billingInfo.name &&
          item.billingInfo.street &&
          item.billingInfo.zipCode &&
          item.billingInfo.city &&
          item.billingInfo.taxId
        ),
        message: "Uzupełnij dane do faktury",
      };
    }
  };
  const wait500ms = async () => {
    await delay(500);
  };

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const recountOrder = async (item) => {
    let payload = item;
    return await dispatch(editItemCart(item.id, payload));
  };

  const Row = ({ order }) => {
    function ccyFormat(num) {
      return `${num.toFixed(2)}`;
    }
    const priceRender = (priceList, order) => {
      let shippingPrice = null;
      let tmpOrderDetails = order.elements
        .filter((e) => e.testType !== "DELIVERY")
        .map((e) => {
          const price = priceList[e.testType];
          if (price) {
            const taxPrice = (price?.price / 100) * (price?.vat / 100);

            const grossPrice = price?.price / 100 + taxPrice;

            return {
              desc: TYPE_TO_NAME_MAP[e.testType],
              type: e.testType,
              unit: e.amount,
              qty: Math.round(parseFloat(grossPrice) * 100) / 100,
              price: Math.round(parseFloat(grossPrice) * e.amount * 100) / 100,
              currency: price?.currency,
            };
          }
        });

      if (priceList["DELIVERY"]) {
        const price = priceList["DELIVERY"];
        if (price) {
          const taxPrice =
            Math.round(
              ((price?.price / 100).toFixed(2) * (price?.vat / 100).toFixed(2) +
                Number.EPSILON) *
                100
            ) / 100;

          shippingPrice = (price?.price / 100 + taxPrice).toFixed(2);
        }
      }
      return `${ccyFormat(
        subtotal(tmpOrderDetails) + parseFloat(shippingPrice)
      )} ${priceList.currency}`;
    };

    function subtotal(items) {
      return items?.map((item) => item?.price).reduce((sum, i) => sum + i, 0);
    }
    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <MuiHeaderTableCell>Test smaku</MuiHeaderTableCell>
              <MuiHeaderTableCell align="right">Test węchu</MuiHeaderTableCell>
              {!smallScreen && (
                <>
                  <MuiHeaderTableCell align="right">
                    Zestaw testów smaku i węchu
                  </MuiHeaderTableCell>
                  <MuiHeaderTableCell
                    align="right"
                    style={{ fontWeight: "bold" }}
                  >
                    Do zapłaty
                  </MuiHeaderTableCell>
                </>
              )}
              {!smallScreen && (
                <>
                  <MuiHeaderTableCell align="right">Status</MuiHeaderTableCell>
                  <MuiHeaderTableCell align="right">Opcje</MuiHeaderTableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <MuiBodyTableCell
                component="th"
                scope="row"
                style={{
                  letterSpacing: "0.27px",
                  // fontSize: "18px",
                }}
              >
                {`${
                  order.elements?.find((e) => e.testType === "TASTE_TEST")
                    ? order.elements?.find((e) => e.testType === "TASTE_TEST")
                        .amount
                    : 0
                } szt.`}
              </MuiBodyTableCell>
              <MuiBodyTableCell
                component="th"
                scope="row"
                style={{
                  letterSpacing: "0.27px",
                  // fontSize: "18px",
                }}
              >
                {`${
                  order.elements?.find((e) => e.testType === "SMELL_TEST")
                    ? order.elements?.find((e) => e.testType === "SMELL_TEST")
                        .amount
                    : 0
                } szt.`}
              </MuiBodyTableCell>
              {!smallScreen && (
                <>
                  <MuiBodyTableCell
                    component="th"
                    scope="row"
                    style={{
                      letterSpacing: "0.27px",
                      // fontSize: "18px",
                    }}
                  >
                    {`${
                      order.elements?.find(
                        (e) => e.testType === "SMELLTASTE_TEST"
                      )
                        ? order.elements?.find(
                            (e) => e.testType === "SMELLTASTE_TEST"
                          ).amount
                        : 0
                    } szt.`}
                  </MuiBodyTableCell>
                  <MuiBodyTableCell
                    component="th"
                    scope="row"
                    style={{
                      letterSpacing: "0.27px",
                      // fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {Object.keys(priceList).length && Object.keys(order).length
                      ? priceRender(priceList, order)
                      : `wczytywanie ceny...`}
                  </MuiBodyTableCell>
                </>
              )}
              {!smallScreen && (
                <>
                  <MuiBodyTableCell align="right">
                    {" "}
                    {typeof dataNotProvided(order)?.isProvided !=
                    "undefined" ? (
                      dataNotProvided(order)?.isProvided ? (
                        <NavLink
                          to={{
                            pathname: "/test-kit",
                            state: {
                              detail: order,
                              cartItemEdition: true,
                            },
                          }}
                          className={classes.link}
                        >
                          Uzupełnij dane
                        </NavLink>
                      ) : (
                        <div style={{ width: "93.47px" }}>
                          <Tooltip title="Gotowe do opłacenia" placement="top">
                            <CheckCircleIcon
                              role="img"
                              aria-hidden="false"
                              alt="gotowe do zakupu"
                              title="gotowe do zakupu"
                              className={classes.icon}
                            />
                          </Tooltip>
                        </div>
                      )
                    ) : (
                      <Tooltip
                        title={
                          !order?.deliveryInfo
                            ? "Brak adresu dostawy"
                            : dataNotProvided(order)?.message
                        }
                        placement="top"
                      >
                        <div>
                          <WarningIcon
                            style={{
                              fill: "#FF9800",
                              cursor: "pointer",
                            }}
                          />
                        </div>
                      </Tooltip>
                    )}
                  </MuiBodyTableCell>
                  <MuiBodyTableCell
                    align="center"
                    style={{ verticalAlign: "top" }}
                  >
                    <Grid container spacing={2}>
                      <Grid item>
                        <Tooltip
                          title={
                            (
                              typeof dataNotProvided(order)?.isProvided !=
                              "undefined"
                                ? dataNotProvided(order)?.isProvided
                                : true
                            )
                              ? `${
                                  dataNotProvided(order)?.message
                                }, aby dokonać zakupu.`
                              : ""
                          }
                          placement="top"
                        >
                          <span>
                            <Button
                              className={classes.buttonOrder}
                              style={{
                                width: "110px",
                                height: "33px",
                                fontWeight: "600",
                              }}
                              variant="outlined"
                              disabled={
                                typeof dataNotProvided(order)?.isProvided !=
                                "undefined"
                                  ? dataNotProvided(order)?.isProvided
                                  : true
                              }
                              onClick={async (e) => {
                                e.stopPropagation();
                                alertAdd({
                                  text: "Dokonywanie zakupu",
                                  timeout: 1000,
                                });
                                setSelectedItemID(order.id);
                                setLoading(true);

                                const myOrderId = await buyCartItem(order.id);

                                const deliveryInfo = order.deliveryInfo
                                let deliveryBasedBillingInfo = null;

                                if (! order.billingInfo) {
                                  deliveryBasedBillingInfo = {
                                    name: deliveryInfo.name,
                                    city: deliveryInfo.city,
                                    post: deliveryInfo.post,
                                    street: deliveryInfo.street,
                                    zipCode: deliveryInfo.zipCode,
                                    taxId: " ",
                                    emailTo: my.mail
                                  }
                                }

                                let response = null;
                                if (order?.elements) {
                                  response = await purchaseItemsPayment(
                                    myOrderId,
                                    order.elements.map((order) => ({
                                      quantity: order.amount,
                                      product: order.testType,
                                    })),
                                    order.billingInfo
                                      ? order.billingInfo
                                      : deliveryBasedBillingInfo
                                  );
                                }
                                // }

                                await wait500ms();

                                if (!response) {
                                  await dispatch(paymentCancel());
                                  response = await purchaseItemsPayment(
                                    myOrderId,
                                    order.elements.map((order) => ({
                                      quantity: order.amount,
                                      product: order.testType,
                                    })),
                                    order.billingInfo
                                      ? order.billingInfo
                                      : deliveryBasedBillingInfo
                                  );
                                }

                                await wait500ms();

                                if (response?.data?.token) {
                                  if (process.env.NODE_ENV === "production") {
                                    window.open(
                                      `${process.env.REACT_APP_P24_PRODUCTION_ADDRESS}/trnRequest/${response.data.token}`,
                                      `_self`
                                    );
                                  } else {
                                    window.open(
                                      `${process.env.REACT_APP_P24_STAGING_ADDRESS}/trnRequest/${response.data.token}`,
                                      `_self`
                                    );
                                  }

                                  setLoading(false);
                                }
                              }}
                            >
                              {loading && selectedItemID === order.id && (
                                <CircularProgress
                                  size={14}
                                  style={{ color: "white" }}
                                />
                              )}
                              {!(loading && selectedItemID === order.id) &&
                                "Kup"}
                            </Button>
                          </span>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Button
                          aria-label="Edytuj zamówienie"
                          className={classes.buttonOrder}
                          style={{
                            width: "110px",
                            height: "33px",
                            fontWeight: "600",
                          }}
                          variant="outlined"
                          onClick={(e) => {
                            e.stopPropagation();
                            history.push({
                              pathname: "/test-kit",
                              state: {
                                detail: order,
                                cartItemEdition: true,
                              },
                            });
                          }}
                        >
                          Edytuj
                        </Button>
                      </Grid>
                      <Grid
                        item
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            askToRemove(order.id);
                            setOpenCartItemRemovingPanel(true);

                            // removeCartItem(order.id);
                          }}
                        >
                          <TrashIcon
                            style={{ cursor: "pointer" }}
                            role="img"
                            aria-hidden="false"
                            alt="Usuń zamówienie z koszyka"
                            title="Usuń zamówienie z koszyka"
                            // onClick={(e) => {
                            //   e.stopPropagation();
                            //   askToRemove(order.id);
                            //   setOpenCartItemRemovingPanel(true);

                            //   // removeCartItem(order.id);
                            // }}
                          />
                        </Button>
                      </Grid>
                    </Grid>
                  </MuiBodyTableCell>
                </>
              )}
            </TableRow>{" "}
          </TableBody>
        </Table>
        {smallScreen && (
          <Table>
            <TableHead>
              <TableRow>
                <MuiHeaderTableCell align="right">
                  Zestaw testów smaku i węchu
                </MuiHeaderTableCell>
                <MuiHeaderTableCell
                  align="right"
                  style={{ fontWeight: "bold" }}
                >
                  Do zapłaty
                </MuiHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <MuiBodyTableCell
                  component="th"
                  scope="row"
                  style={{
                    letterSpacing: "0.27px",
                    // fontSize: "18px",
                  }}
                >
                  {`${
                    order.elements?.find(
                      (e) => e.testType === "SMELLTASTE_TEST"
                    )
                      ? order.elements?.find(
                          (e) => e.testType === "SMELLTASTE_TEST"
                        ).amount
                      : 0
                  } szt.`}
                </MuiBodyTableCell>
                <MuiBodyTableCell
                  component="th"
                  scope="row"
                  style={{
                    letterSpacing: "0.27px",
                    // fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  {Object.keys(priceList).length && Object.keys(order).length
                    ? priceRender(priceList, order)
                    : `wczytywanie ceny...`}
                </MuiBodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
        {smallScreen && (
          <Table>
            <TableHead>
              <TableRow>
                <MuiHeaderTableCell align="right">Status</MuiHeaderTableCell>
                <MuiHeaderTableCell align="right" colSpan={2}>
                  Opcje
                </MuiHeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <MuiBodyTableCell align="right">
                  {" "}
                  {typeof dataNotProvided(order)?.isProvided != "undefined" ? (
                    dataNotProvided(order)?.isProvided ? (
                      <NavLink
                        to={{
                          pathname: "/test-kit",
                          state: {
                            detail: order,
                            cartItemEdition: true,
                          },
                        }}
                        className={classes.link}
                      >
                        Uzupełnij dane
                      </NavLink>
                    ) : (
                      <div style={{ width: "93.47px" }}>
                        <CheckCircleIcon className={classes.icon} />
                      </div>
                    )
                  ) : (
                    <NavLink
                      to={{
                        pathname: "/test-kit",
                        state: {
                          detail: order,
                          cartItemEdition: true,
                        },
                      }}
                    >
                      Uzupełnij dane
                    </NavLink>
                  )}
                </MuiBodyTableCell>
                <MuiBodyTableCell
                  align="center"
                  style={{ verticalAlign: "top" }}
                >
                  <Grid container spacing={2}>
                    <Grid item>
                      <Button
                        className={classes.buttonOrder}
                        style={{
                          width: "110px",
                          height: "33px",
                          fontWeight: "600",
                          // borderColor: "#102655",
                        }}
                        variant="outlined"
                        disabled={
                          typeof dataNotProvided(order)?.isProvided !=
                          "undefined"
                            ? dataNotProvided(order)?.isProvided
                            : true
                        }
                        onClick={async (e) => {
                          e.stopPropagation();
                          alertAdd({
                            text: "Dokonywanie zakupu",
                            timeout: 1000,
                          });
                          setSelectedItemID(order.id);
                          setLoading(true);

                          const myOrderId = await buyCartItem(order.id);

                          const deliveryInfo = order.deliveryInfo
                          let deliveryBasedBillingInfo = null;

                          if (! order.billingInfo) {
                            deliveryBasedBillingInfo = {
                              name: deliveryInfo.name,
                              city: deliveryInfo.city,
                              post: deliveryInfo.post,
                              street: deliveryInfo.street,
                              zipCode: deliveryInfo.zipCode,
                              taxId: " ",
                              emailTo: my.mail
                            }
                          }

                          let response = null;

                          if (order?.elements) {
                            response = await purchaseItemsPayment(
                              myOrderId,
                              order.elements.map((order) => ({
                                quantity: order.amount,
                                product: order.testType,
                              })),
                              order.billingInfo ? order.billingInfo : deliveryBasedBillingInfo
                            );
                          }
                          // }

                          await wait500ms();

                          if (!response) {
                            await dispatch(paymentCancel());
                            response = await purchaseItemsPayment(
                              myOrderId,
                              order.elements.map((order) => ({
                                quantity: order.amount,
                                product: order.testType,
                              })),
                              order.billingInfo ? order.billingInfo : deliveryBasedBillingInfo
                            );
                          }

                          await wait500ms();

                          if (response?.data?.token) {
                            if (process.env.NODE_ENV === "production") {
                              window.open(
                                `${process.env.REACT_APP_P24_PRODUCTION_ADDRESS}/trnRequest/${response.data.token}`,
                                `_self`
                              );
                            } else {
                              window.open(
                                `${process.env.REACT_APP_P24_STAGING_ADDRESS}/trnRequest/${response.data.token}`,
                                `_self`
                              );
                            }

                            setLoading(false);
                          }
                        }}
                      >
                        {loading && selectedItemID === order.id && (
                          <CircularProgress
                            size={14}
                            style={{ color: "white" }}
                          />
                        )}
                        {!(loading && selectedItemID === order.id) && "Kup"}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        className={classes.buttonOrder}
                        style={{
                          width: "110px",
                          height: "33px",
                          fontWeight: "600",
                          // borderColor: "#102655",
                        }}
                        variant="outlined"
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push({
                            pathname: "/test-kit",
                            state: {
                              detail: order,
                              cartItemEdition: true,
                            },
                          });
                        }}
                      >
                        Edytuj
                      </Button>
                    </Grid>
                    <Grid
                      item
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          askToRemove(order.id);
                          setOpenCartItemRemovingPanel(true);

                          // removeCartItem(order.id);
                        }}
                      >
                        <TrashIcon
                          style={{ cursor: "pointer" }}
                          // onClick={(e) => {
                          //   e.stopPropagation();
                          //   askToRemove(order.id);
                          //   setOpenCartItemRemovingPanel(true);

                          //   // removeCartItem(order.id);
                          // }}
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </MuiBodyTableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
    );
  };

  const handleClose = () => {
    setOpenCartItemRemovingPanel(false);
  };

  const page = () => (
    <Can
      permission="test-kit:page"
      ok={() =>
        paymentStarting ? (
          <Grid container>
            <Grid item xs={12}>
              <Box p={2}>
                <Loader
                  loading={true}
                  text="Za chwilę nastąpi przekierowanie na stronę płatności"
                />
              </Box>
            </Grid>
          </Grid>
        ) : cartItems && cartItems.length === 0 ? (
          <Grid container className={classes.mainGrid}>
            <AppBarTitle value="Koszyk zamówień" />

            <Grid
              item
              container
              xs={12}
              spacing={3}
              className={classes.contentGrid}
            >
              <Grid item xs={12} className={classes.itemGrid}>
                <p className={classes.label}>Nie masz jeszcze nic w koszyku.</p>
              </Grid>

              <Grid item xs={12} className={classes.itemGrid}>
                <Button
                  // className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={() => history.push("/test-kit")}
                >
                  {/* <NavLink
                    to="/test-kit"
                    style={{ color: theme.palette.background.default }}
                  > */}
                  DODAJ ZAMÓWIENIE
                  {/* </NavLink> */}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Container className={classes.container}>
            <AppBarTitle value="Koszyk zamówień" />
            <h1
              aria-hidden="false"
              aria-label="Koszyk zamówień"
              style={{ display: "none" }}
            >
              Koszyk zamówień
            </h1>
            <Grid container>
              {expandedOrders.length === 0 && (
                <Grid
                  xs={12}
                  item
                  className={classes.orderButtonGrid}
                  elevation={15}
                >
                  <Button
                    className={classes.newOrderButton}
                    variant="contained"
                    color="primary"
                    onClick={() => history.push("/test-kit")}
                  >
                    {/* <NavLink
                      to="/test-kit"
                      style={{ color: theme.palette.background.default }}
                    > */}
                    DODAJ ZAMÓWIENIE
                    {/* </NavLink> */}
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} lg={10} xl={8} className={classes.gridTable}>
                {" "}
                <Box p={2}>
                  <Typography
                    variant="h5"
                    component="h2"
                    gutterBottom
                    className={classes.title}
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Twój koszyk
                  </Typography>
                </Box>
                {cartItems?.slice(0, 1).map((order, id) => (
                  <Accordion
                    style={{
                      boxShadow: "none",
                    }}
                    key={id}
                    expanded={
                      expandedOrders.find(
                        (expandedOrder) => expandedOrder.id === order.id
                      )
                        ? true
                        : false
                    }
                  >
                    <AccordionSummary
                      className={classes.summary}
                      className={
                        (
                          typeof dataNotProvided(order)?.isProvided !=
                          "undefined"
                            ? dataNotProvided(order)?.isProvided
                            : true
                        )
                          ? classes.buttonBase
                          : classes.buttonBase1
                      }
                      style={{
                        flexDirection: "row-reverse",

                        cursor: (
                          typeof dataNotProvided(order)?.isProvided !=
                          "undefined"
                            ? dataNotProvided(order)?.isProvided
                            : true
                        )
                          ? "default"
                          : "pointer",
                      }}
                      expandIcon={
                        (
                          typeof dataNotProvided(order)?.isProvided !=
                          "undefined"
                            ? dataNotProvided(order)?.isProvided
                            : true
                        ) ? (
                          <div style={{ height: "24px", width: "24px" }}></div>
                        ) : (
                          <ExpandMoreIcon className={classes.title} />
                        )
                      }
                      onClick={() => {
                        if (
                          typeof dataNotProvided(order)?.isProvided !=
                            "undefined" &&
                          !dataNotProvided(order)?.isProvided
                        ) {
                          if (
                            expandedOrders.find(
                              (expandedOrder) => expandedOrder.id === order.id
                            )
                          ) {
                            setExpandedOrders(
                              expandedOrders.filter(
                                (expandedOrder) => expandedOrder.id !== order.id
                              )
                            );
                          } else {
                            setExpandedOrders([
                              ...expandedOrders,
                              { id: order.id },
                            ]);
                          }
                        }
                      }}
                    >
                      {(
                        expandedOrders.find(
                          (expandedOrder) => expandedOrder.id === order.id
                        )
                          ? true
                          : false
                      ) ? (
                        <p
                          className={classes.titleDetails}
                          style={{
                            // color: "#102655",
                            letterSpacing: "0.72px",
                            // fontSize: "24px",
                          }}
                        >
                          Szczegóły zamówienia
                        </p>
                      ) : (
                        <Row key={id} order={order} />
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      {Object.keys(order).length &&
                      Object.keys(priceList).length &&
                      Object.keys(expandedOrders).length ? (
                        <OrderSummary
                          order={order}
                          priceList={priceList}
                          expandedOrders={expandedOrders}
                          recountOrder={recountOrder}
                        />
                      ) : null}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
              <Grid item xs={12} lg={10} xl={8} className={classes.gridTable}>
                <hr style={{ backgroundColor: theme.palette.hr.primary }} />
              </Grid>
              {idToRemove !== NO_ID_TO_REMOVE && (
                <div>
                  <Dialog
                    open={openCartItemRemovingPanel}
                    onClose={handleClose}
                    maxWidth={"md"}
                    fullWidth={true}
                  >
                    <DialogTitle>{`Usuń zamówienie`}</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <span
                          className={classes.spanText}
                        >{`Czy na pewno usunąć całe zamówienie?`}</span>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <DisagreeButton
                        onClick={() => {
                          setIdToRemove(NO_ID_TO_REMOVE);
                          handleClose();
                        }}
                      >
                        Nie usuwaj
                      </DisagreeButton>
                      <AgreeButton
                        onClick={() => {
                          removeCartItem(idToRemove);
                          setIdToRemove(NO_ID_TO_REMOVE);
                        }}
                        autoFocus
                      >
                        Tak, usuń
                      </AgreeButton>
                    </DialogActions>
                  </Dialog>
                </div>
              )}{" "}
              {cartItems.slice(1, cartItems.length).length > 0 && (
                <Grid item xs={12} lg={10} xl={8} className={classes.gridTable}>
                  <Box p={2}>
                    <Typography
                      variant="h5"
                      component="h2"
                      gutterBottom
                      className={classes.title}
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Dokończ zakupy
                    </Typography>
                  </Box>
                  {cartItems.slice(1, cartItems.length).map((order, id) => (
                    <Accordion
                      style={{
                        boxShadow: "none",
                        borderBottom: "1px solid #707070",
                      }}
                      key={id}
                      expanded={
                        expandedOrders.find(
                          (expandedOrder) => expandedOrder.id === order.id
                        )
                          ? true
                          : false
                      }
                    >
                      <AccordionSummary
                        className={classes.summary}
                        className={
                          (
                            typeof dataNotProvided(order)?.isProvided !=
                            "undefined"
                              ? dataNotProvided(order)?.isProvided
                              : true
                          )
                            ? classes.buttonBase
                            : classes.buttonBase1
                        }
                        style={{
                          flexDirection: "row-reverse",

                          cursor: (
                            typeof dataNotProvided(order)?.isProvided !=
                            "undefined"
                              ? dataNotProvided(order)?.isProvided
                              : true
                          )
                            ? "default"
                            : "pointer",
                        }}
                        expandIcon={
                          (
                            typeof dataNotProvided(order)?.isProvided !=
                            "undefined"
                              ? dataNotProvided(order)?.isProvided
                              : true
                          ) ? (
                            <div
                              style={{ height: "24px", width: "24px" }}
                            ></div>
                          ) : (
                            <ExpandMoreIcon className={classes.title} />
                          )
                        }
                        onClick={() => {
                          if (
                            typeof dataNotProvided(order)?.isProvided !=
                              "undefined" &&
                            !dataNotProvided(order)?.isProvided
                          ) {
                            if (
                              expandedOrders.find(
                                (expandedOrder) => expandedOrder.id === order.id
                              )
                            ) {
                              setExpandedOrders(
                                expandedOrders.filter(
                                  (expandedOrder) =>
                                    expandedOrder.id !== order.id
                                )
                              );
                            } else {
                              setExpandedOrders([
                                ...expandedOrders,
                                { id: order.id },
                              ]);
                            }
                          }
                        }}
                      >
                        {(
                          expandedOrders.find(
                            (expandedOrder) => expandedOrder.id === order.id
                          )
                            ? true
                            : false
                        ) ? (
                          <p
                            className={classes.titleDetails}
                            style={{
                              // color: "#102655",
                              letterSpacing: "0.72px",
                              // fontSize: "24px",
                            }}
                          >
                            Szczegóły zamówienia
                          </p>
                        ) : (
                          <Row key={id} order={order} />
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        {Object.keys(order).length &&
                        Object.keys(priceList).length &&
                        Object.keys(expandedOrders).length ? (
                          <OrderSummary
                            order={order}
                            priceList={priceList}
                            expandedOrders={expandedOrders}
                            recountOrder={recountOrder}
                          />
                        ) : null}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
              )}
            </Grid>
          </Container>
        )
      }
      not={() => <div></div>}
    />
  );

  const redirect = () => <Redirect to="/login" />;

  return <Can permission="shopping-cart:page" ok={page} not={redirect} />;
};

const mapStateToProps = (state) => ({
  cartItems: state.cartItems,
  paymentStarting: state.paymentStarting,
});

const mapDispatchToProps = (dispatch) => ({
  removeCartItem: (cartItemId) => dispatch(removeCartItem(cartItemId)),
  buyCartItem: (cartItemId) => dispatch(buyCartItem(cartItemId)),
  purchaseItemPayment: ({ product, quantity, myOrderId, billingInfo }) =>
    dispatch(
      purchaseItemPayment({ product, quantity, myOrderId, billingInfo })
    ),
  purchaseItemsPayment: (myOrderId, items, billingInfo) =>
    dispatch(purchaseItemsPayment(myOrderId, items, billingInfo)),
  paymentCancel: () => dispatch(paymentCancel()),
  alertAdd: (payload) => dispatch(alertAdd(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
