import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import RemoveOutlinedIcon from "@material-ui/icons/RemoveOutlined";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import AppBarTitle from "../../components/app-bar-title";
import { fetchOrderSummaryOrderId } from "../../redux/actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { fetchPriceList } from "../../redux/shopping-cart/actions";
import {
  buyCartItem,
  removeCartItem,
  purchaseItemPayment,
  purchaseItemsPayment,
  paymentCancel,
} from "../../redux/shopping-cart/actions";
import Loader from "../../components/loader";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "3000px",
    minWidth: "300px",
    padding: "0px",
  },

  headerCell: {
    padding: "8px 16px 0px 16px",
    fontSize: theme.typography.text,
  },
  card: {
    boxShadow: "0px 0px 15px #000000BF",
    borderRadius: "20px",
    opacity: "0.55",
  },
  item: {
    fontSize: theme.typography.text,
    color: theme.palette.text.item,
  },
  titleSummary: {
    color: theme.palette.text.alternative2,
  },
}));

const MuiHeaderTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    // padding: "16px 16px 0px 16px",
    fontSize: theme.typography.leftMenuLabel,
    color: theme.palette.text.alternative3,
    letterSpacing: "0.24px",
  },
}))(TableCell);

const MuiBodyTableCell = withStyles((theme) => ({
  root: {
    borderBottom: "none",
    color: theme.palette.text.alternative3,
    fontWeight: "bold",
    // padding: "8px 16px 0px 16px",
  },
}))(TableCell);

const MuiSummaryBodyTableCell = withStyles({
  root: {
    borderTop: "1px solid grey",
    borderBottom: "none",
    color: "#122140",
    fontWeight: "bold",
    // padding: "8px 16px 0px 16px",
  },
})(TableCell);

const TYPE_TO_NAME_MAP = {
  SMELLTASTE_TEST: "Test smaku i węchu",
  SMELL_TEST: "Test węchu",
  TASTE_TEST: "Test smaku",
  DELIVERY: "-",
};

const ORDER_MAP = {
  SMELLTASTE_TEST: "a",
  SMELL_TEST: "b",
  TASTE_TEST: "c",
  DELIVERY: "-",
};

const OrderSummary = ({ order, priceList, expandedOrders, recountOrder }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rows, setRows] = useState(null);
  const [item, setItem] = useState(null);

  const history = useHistory();
  const theme = useTheme();

  const [testsAmount, setTestsAmount] = useState({});

  const [shippingCost, setShippingCost] = useState(null);

  const [pendingRequest, setPendingRequest] = useState(false);
  const [preventPurchase, setPreventPurchase] = useState(false);

  //   const priceList = useSelector((s) => s.priceList || null);
  const my = useSelector((s) => s.my);

  const fetchOrderData = async (orderId) => {
    const data = await dispatch(fetchOrderSummaryOrderId(orderId));
    setItem(data);
  };

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const xSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  useEffect(() => {
    if (order && expandedOrders?.find((o) => o.id === order.id)) {
      fetchOrderData(order.id);
    }
    setTestsAmount({
      ...(order?.elements.find((e) => e.testType === "SMELLTASTE_TEST") && {
        SMELLTASTE_TEST: order?.elements.find(
          (e) => e.testType === "SMELLTASTE_TEST"
        ).amount,
      }),
      ...(order?.elements.find((e) => e.testType === "SMELL_TEST") && {
        SMELL_TEST: order?.elements.find((e) => e.testType === "SMELL_TEST")
          .amount,
      }),
      ...(order?.elements.find((e) => e.testType === "TASTE_TEST") && {
        TASTE_TEST: order?.elements.find((e) => e.testType === "TASTE_TEST")
          .amount,
      }),
    });
  }, [order, expandedOrders]);

  useEffect(() => {
    setPreventPurchase(
      order.elements.filter(
        (e) => testsAmount[e.testType] !== e.amount && e.testType !== "DELIVERY"
      ).length > 0
        ? true
        : false
    );
  }, [testsAmount]);

  useEffect(() => {
    if (Object.keys(priceList).length === 0) {
      dispatch(fetchPriceList());
    }

    if (priceList["DELIVERY"]) {
      const price = priceList["DELIVERY"];
      const taxPrice =
        Math.round(
          ((price.price / 100).toFixed(2) * (price.vat / 100).toFixed(2) +
            Number.EPSILON) *
            100
        ) / 100;

      const grossPrice = (price.price / 100 + taxPrice).toFixed(2);

      setShippingCost(parseFloat(grossPrice));
    }

    if (item && priceList) {
      let tmpRows = item.elements
        .filter((e) => e.testType !== "DELIVERY")
        .map((e) => {
          const price = priceList[e.testType];
          if (price) {
            const taxPrice = (price.price / 100) * (price.vat / 100);
            // Math.round(
            //   ((price.price / 100).toFixed(2) * (price.vat / 100).toFixed(2) +
            //     Number.EPSILON) *
            //     100
            // ) / 100;

            const grossPrice = price.price / 100 + taxPrice;
            // (price.price / 100 + taxPrice).toFixed(2);

            return {
              desc: TYPE_TO_NAME_MAP[e.testType],
              type: e.testType,
              unit: e.amount,
              qty: Math.round(parseFloat(grossPrice) * 100) / 100,
              price: Math.round(parseFloat(grossPrice) * e.amount * 100) / 100,
              currency: price.currency,
              sortOrder: ORDER_MAP[e.testType],
            };
          }
        });
      setRows(tmpRows.sort((a, b) => b.sortOrder.localeCompare(a.sortOrder)));
    }
  }, [item, priceList]);

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  function subtotal(items) {
    return items
      ?.filter(({ price }) => price)
      .map(({ price }) => price)
      .reduce((sum, i) => sum + i, 0);
  }

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const wait500ms = async () => {
    await delay(500);
  };

  const invoiceSubtotal = subtotal(rows || []);
  const invoiceTaxes = shippingCost;
  const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  return rows ? (
    <Grid container spacing={2}>
      <Grid item container xs={12} md={8} style={{ padding: "18px" }}>
        <Grid item xs={12}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <MuiHeaderTableCell className={classes.headerCell}>
                    Artykuł
                  </MuiHeaderTableCell>
                  <MuiHeaderTableCell
                    className={classes.headerCell}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Cena z VAT
                  </MuiHeaderTableCell>
                  <MuiHeaderTableCell
                    className={classes.headerCell}
                    style={{ textAlign: "center" }}
                  >
                    Ilość
                  </MuiHeaderTableCell>
                  <MuiHeaderTableCell
                    className={classes.headerCell}
                    style={{ textAlign: "right" }}
                  >
                    Suma z VAT
                  </MuiHeaderTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .filter((r) => r.type !== "DELIVERY")
                  .map((row) => (
                    <TableRow key={row.desc}>
                      <MuiBodyTableCell style={{ fontWeight: "bold" }}>
                        <Typography
                          gutterBottom
                          className={classes.item}
                          style={{
                            letterSpacing: "0.6px",
                            fontWeight: "bold",
                          }}
                        >
                          {row.desc}
                        </Typography>
                      </MuiBodyTableCell>
                      <MuiBodyTableCell
                        align="right"
                        style={{ textAlign: "left" }}
                      >
                        <Typography gutterBottom className={classes.item}>
                          {`${row.qty} ${row.currency}`}
                        </Typography>
                      </MuiBodyTableCell>
                      <MuiBodyTableCell
                        align="right"
                        style={{ textAlign: "center" }}
                      >
                        <Typography gutterBottom className={classes.item}>
                          {smallScreen ? (
                            <Grid
                              container
                              direction="row"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Grid item xs={12} sm={4}>
                                <IconButton
                                  style={{ padding: "12px 12px 12px 0px" }}
                                  onClick={() => {
                                    setTestsAmount({
                                      ...testsAmount,
                                      [row.type]:
                                        testsAmount[row.type] > 1
                                          ? testsAmount[row.type] - 1
                                          : 1,
                                    });
                                  }}
                                >
                                  <RemoveOutlinedIcon
                                    role="img"
                                    aria-hidden="false"
                                    alt="zmniejsza wartość w koszyku o 1"
                                    title="zmniejsza wartość w koszyku o 1"
                                    style={{
                                      border: `1px solid ${theme.palette.text.primary}`,
                                      opacity: "1",
                                      borderBottomLeftRadius: "5px",
                                      borderTopLeftRadius: "5px",
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                              <Grid item xs={12} sm={4}>{`${
                                testsAmount[row.type]
                              } szt.`}</Grid>
                              <Grid item xs={12} sm={4}>
                                <IconButton
                                  onClick={() => {
                                    setTestsAmount({
                                      ...testsAmount,
                                      [row.type]: testsAmount[row.type] + 1,
                                    });
                                  }}
                                >
                                  <AddOutlinedIcon
                                    role="img"
                                    aria-hidden="false"
                                    alt="zwiększa wartość w koszyku o 1"
                                    title="zwiększa wartość w koszyku o 1"
                                    style={{
                                      border: `1px solid ${theme.palette.text.primary}`,
                                      opacity: "1",
                                      borderBottomRightRadius: "5px",
                                      borderTopRightRadius: "5px",
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>
                          ) : (
                            <>
                              <IconButton
                                style={{ padding: "12px 12px 12px 0px" }}
                                onClick={() => {
                                  setTestsAmount({
                                    ...testsAmount,
                                    [row.type]:
                                      testsAmount[row.type] > 1
                                        ? testsAmount[row.type] - 1
                                        : 1,
                                  });
                                }}
                              >
                                <RemoveOutlinedIcon
                                  role="img"
                                  aria-hidden="false"
                                  alt="zmniejsza wartość w koszyku o 1"
                                  title="zmniejsza wartość w koszyku o 1"
                                  style={{
                                    border: `1px solid ${theme.palette.text.primary}`,
                                    opacity: "1",
                                    borderBottomLeftRadius: "5px",
                                    borderTopLeftRadius: "5px",
                                  }}
                                />
                              </IconButton>
                              {`${testsAmount[row.type]} szt.`}
                              <IconButton
                                onClick={() => {
                                  setTestsAmount({
                                    ...testsAmount,
                                    [row.type]: testsAmount[row.type] + 1,
                                  });
                                }}
                              >
                                <AddOutlinedIcon
                                  role="img"
                                  aria-hidden="false"
                                  alt="zwiększa wartość w koszyku o 1"
                                  title="zwiększa wartość w koszyku o 1"
                                  style={{
                                    border: `1px solid ${theme.palette.text.primary}`,
                                    opacity: "1",
                                    borderBottomRightRadius: "5px",
                                    borderTopRightRadius: "5px",
                                  }}
                                />
                              </IconButton>
                            </>
                          )}
                        </Typography>
                      </MuiBodyTableCell>
                      <MuiBodyTableCell
                        align="right"
                        style={{ textAlign: "right" }}
                      >
                        <Typography gutterBottom className={classes.item}>
                          {`${ccyFormat(row.price)}  ${row.currency}`}
                        </Typography>
                      </MuiBodyTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {!smallScreen && (
        <Grid item xs={12} md={4}>
          <Card className={classes.root}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{
                      fontWeight: "bold",
                    }}
                    className={classes.titleSummary}
                  >
                    Podsumowanie
                  </Typography>{" "}
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <MuiBodyTableCell style={{ textAlign: "left" }}>
                            <Typography gutterBottom className={classes.item}>
                              {`Wartość zamówienia`}
                            </Typography>
                          </MuiBodyTableCell>
                          <MuiBodyTableCell
                            align="right"
                            style={{ textAlign: "right" }}
                          >
                            <Typography gutterBottom className={classes.item}>
                              {`${ccyFormat(invoiceSubtotal)}  ${
                                priceList.currency
                              }`}
                            </Typography>
                          </MuiBodyTableCell>
                        </TableRow>
                        <TableRow>
                          <MuiBodyTableCell style={{ textAlign: "left" }}>
                            <Typography gutterBottom className={classes.item}>
                              Dostawa
                            </Typography>
                          </MuiBodyTableCell>
                          <MuiBodyTableCell
                            align="right"
                            style={{ textAlign: "right" }}
                          >
                            <Typography gutterBottom className={classes.item}>
                              {`${ccyFormat(shippingCost || invoiceTaxes)}  ${
                                priceList.currency
                              }`}
                            </Typography>
                          </MuiBodyTableCell>
                        </TableRow>
                        <TableRow style={{ borderTop: "1px solid gray" }}>
                          <MuiBodyTableCell style={{ textAlign: "left" }}>
                            <Typography gutterBottom className={classes.item}>
                              {`Suma z VAT`}
                            </Typography>
                          </MuiBodyTableCell>
                          <MuiBodyTableCell
                            align="right"
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                              className={classes.titleSummary}
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {`${ccyFormat(invoiceTotal)}  ${
                                priceList.currency
                              }`}
                            </Typography>
                          </MuiBodyTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              {preventPurchase ? (
                <Button
                  variant="outlined"
                  style={{
                    padding: "8px 36px",
                    color: theme.palette.text.alternative2,
                    fontSize: theme.typography.text,
                    letterSpacing: "0.45px",
                    border: `0.5px solid ${theme.palette.text.alternative2}`,
                  }}
                  fullWidth
                  disabled={pendingRequest}
                  onClick={async () => {
                    setPendingRequest(true);
                    if (
                      await recountOrder({
                        ...item,
                        elements: item.elements.map((e) => ({
                          ...e,
                          amount:
                            e.testType !== "DELIVERY"
                              ? testsAmount[e.testType]
                              : 1,
                        })),
                      })
                    ) {
                      setPendingRequest(false);
                    }
                  }}
                >
                  {pendingRequest && <CircularProgress size={20} />}
                  {!pendingRequest && "PRZELICZ KOSZYK"}
                </Button>
              ) : (
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  fullWidth
                  style={{
                    padding: "8px 36px",
                    background: `${theme.palette.text.alternative} 0% 0% no-repeat padding-box`,
                    borderRadius: "8px",
                    letterSpacing: "0.81px",
                    fontSize: theme.typography.text,
                  }}
                  disabled={pendingRequest}
                  onClick={async () => {
                    const myOrderId = await dispatch(buyCartItem(item.id));

                    const deliveryInfo = item.deliveryInfo
                    let deliveryBasedBillingInfo = null;

                    if (! item.billingInfo) {
                      deliveryBasedBillingInfo = {
                        name: deliveryInfo.name,
                        city: deliveryInfo.city,
                        post: deliveryInfo.post,
                        street: deliveryInfo.street,
                        zipCode: deliveryInfo.zipCode,
                        taxId: " ",
                        emailTo: my.mail
                      }
                    }

                    let response = null;
                    if (item?.elements) {
                      response = await dispatch(
                        purchaseItemsPayment(
                          myOrderId,
                          item.elements.map((item) => ({
                            quantity: item.amount,
                            product: item.testType,
                          })),
                          item.billingInfo
                            ? {
                                ...item.billingInfo,
                                emailTo: my.mail,
                              }
                            : deliveryBasedBillingInfo
                        )
                      );
                    }
                    // }

                    await wait500ms();

                    if (!response) {
                      await dispatch(paymentCancel());
                      response = await dispatch(
                        purchaseItemsPayment(
                          myOrderId,
                          item.elements.map((item) => ({
                            quantity: item.amount,
                            product: item.testType,
                          })),
                          item.billingInfo
                            ? {
                                ...item.billingInfo,
                                emailTo: my.mail,
                              }
                            : deliveryBasedBillingInfo
                        )
                      );
                    }

                    await wait500ms();

                    if (response?.data?.token) {
                      if (process.env.NODE_ENV === "production") {
                        window.open(
                          `${process.env.REACT_APP_P24_PRODUCTION_ADDRESS}/trnRequest/${response.data.token}`,
                          `_self`
                        );
                      } else {
                        window.open(
                          `${process.env.REACT_APP_P24_STAGING_ADDRESS}/trnRequest/${response.data.token}`,
                          `_self`
                        );
                      }
                    }
                  }}
                >
                  {pendingRequest && <CircularProgress size={20} />}
                  {!pendingRequest && `PŁATNOŚĆ`}
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      )}
      <Grid item xs={12} container style={{ margin: "auto", padding: "12px" }}>
        {item.deliveryInfo && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            {" "}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <MuiHeaderTableCell
                      align="center"
                      style={{
                        fontSize: theme.typography.text,
                        fontWeight: "600",
                      }}
                    >
                      Adres dostawy
                    </MuiHeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      Imię Nazwisko:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item.deliveryInfo.name}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      Ulica:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item.deliveryInfo.street}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      Miejscowość:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item.deliveryInfo.city}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      Kod pocztowy:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {`${item.deliveryInfo.zipCode}`}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      Poczta:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {`${item.deliveryInfo.post || "---"}`}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      Telefon:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {`${item.deliveryInfo.phone || "---"}`}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                  {item.deliveryInfo.mail && (
                    <TableRow>
                      <MuiHeaderTableCell className={classes.headerCell}>
                        {`email: ${item.deliveryInfo.mail}`}
                      </MuiHeaderTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        {item.billingInfo && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            {xSmallScreen && <hr />}
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <MuiHeaderTableCell
                      align="center"
                      style={{
                        fontSize: theme.typography.text,
                        fontWeight: "600",
                      }}
                    >
                      Dane do faktury
                    </MuiHeaderTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      Nazwa:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item.billingInfo.name}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      Ulica:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item.billingInfo.street}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      Miejscowość:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item.billingInfo.city}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      Kod pocztowy:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item.billingInfo.zipCode}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      Poczta:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item.billingInfo.post || "---"}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                  <TableRow>
                    <MuiHeaderTableCell className={classes.headerCell}>
                      NIP:{" "}
                      <span style={{ fontWeight: "600" }}>
                        {item.billingInfo.taxId}
                      </span>
                    </MuiHeaderTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Button
          size="small"
          color="primary"
          variant="contained"
          style={{
            height: "35px",
            width: "160px",
          }}
          onClick={() => {
            history.push({
              pathname: "/test-kit",
              state: {
                detail: item,
                cartItemEdition: true,
              },
            });
          }}
        >
          Zmień dane
        </Button>
      </Grid>
      {smallScreen && (
        <Grid item xs={12} md={4}>
          <Card className={classes.root}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    className={classes.titleSummary}
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Podsumowanie
                  </Typography>{" "}
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <MuiBodyTableCell style={{ textAlign: "left" }}>
                            <Typography gutterBottom className={classes.item}>
                              {`Wartość zamówienia`}
                            </Typography>
                          </MuiBodyTableCell>
                          <MuiBodyTableCell
                            align="right"
                            style={{ textAlign: "right" }}
                          >
                            <Typography gutterBottom className={classes.item}>
                              {`${ccyFormat(invoiceSubtotal)}  ${
                                priceList.currency
                              }`}
                            </Typography>
                          </MuiBodyTableCell>
                        </TableRow>
                        <TableRow>
                          <MuiBodyTableCell style={{ textAlign: "left" }}>
                            <Typography gutterBottom className={classes.item}>
                              Dostawa
                            </Typography>
                          </MuiBodyTableCell>
                          <MuiBodyTableCell
                            align="right"
                            style={{ textAlign: "right" }}
                          >
                            <Typography gutterBottom className={classes.item}>
                              {`${ccyFormat(shippingCost || invoiceTaxes)}  ${
                                priceList.currency
                              }`}
                            </Typography>
                          </MuiBodyTableCell>
                        </TableRow>
                        <TableRow style={{ borderTop: "1px solid gray" }}>
                          <MuiBodyTableCell>
                            <Typography gutterBottom className={classes.item}>
                              {`Suma z VAT`}
                            </Typography>
                          </MuiBodyTableCell>
                          <MuiBodyTableCell
                            align="right"
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <Typography
                              gutterBottom
                              variant="h5"
                              component="h2"
                              style={{
                                fontWeight: "bold",
                              }}
                              className={classes.titleSummary}
                            >
                              {`${ccyFormat(invoiceTotal)}  ${
                                priceList.currency
                              }`}
                            </Typography>
                          </MuiBodyTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              {preventPurchase ? (
                <Button
                  variant="outlined"
                  style={{
                    padding: "8px 36px",
                    color: theme.palette.text.alternative2,
                    fontSize: theme.typography.text,
                    letterSpacing: "0.45px",
                    border: `0.5px solid ${theme.palette.text.alternative2}`,
                  }}
                  fullWidth
                  disabled={pendingRequest}
                  onClick={async () => {
                    setPendingRequest(true);
                    if (
                      await recountOrder({
                        ...item,
                        elements: item.elements.map((e) => ({
                          ...e,
                          amount:
                            e.testType !== "DELIVERY"
                              ? testsAmount[e.testType]
                              : 1,
                        })),
                      })
                    ) {
                      setPendingRequest(false);
                    }
                  }}
                >
                  {pendingRequest && <CircularProgress size={20} />}
                  {!pendingRequest && "PRZELICZ KOSZYK"}
                </Button>
              ) : (
                <Button
                  size="small"
                  color="primary"
                  variant="contained"
                  fullWidth
                  style={{
                    padding: "8px 36px",
                    background: `${theme.palette.text.alternative} 0% 0% no-repeat padding-box`,
                    borderRadius: "8px",
                    letterSpacing: "0.81px",
                    fontSize: theme.typography.text,
                  }}
                  disabled={pendingRequest}
                  onClick={async () => {
                    const myOrderId = await dispatch(buyCartItem(item.id));

                    const deliveryInfo = item.deliveryInfo
                    let deliveryBasedBillingInfo = null;

                    if (! item.billingInfo) {
                      deliveryBasedBillingInfo = {
                        name: deliveryInfo.name,
                        city: deliveryInfo.city,
                        post: deliveryInfo.post,
                        street: deliveryInfo.street,
                        zipCode: deliveryInfo.zipCode,
                        taxId: " ",
                        emailTo: my.mail
                      }
                    }

                    let response = null;
                    if (item?.elements) {
                      response = await dispatch(
                        purchaseItemsPayment(
                          myOrderId,
                          item.elements.map((item) => ({
                            quantity: item.amount,
                            product: item.testType,
                          })),
                          item.billingInfo
                            ? {
                                ...item.billingInfo,
                                emailTo: my.mail,
                              }
                            : deliveryBasedBillingInfo
                        )
                      );
                    }
                    // }

                    await wait500ms();

                    if (!response) {
                      await dispatch(paymentCancel());
                      response = await dispatch(
                        purchaseItemsPayment(
                          myOrderId,
                          item.elements.map((item) => ({
                            quantity: item.amount,
                            product: item.testType,
                          })),
                          item.billingInfo
                            ? {
                                ...item.billingInfo,
                                emailTo: my.mail,
                              }
                            : deliveryBasedBillingInfo
                        )
                      );
                    }

                    await wait500ms();

                    if (response?.data?.token) {
                      if (process.env.NODE_ENV === "production") {
                        window.open(
                          `${process.env.REACT_APP_P24_PRODUCTION_ADDRESS}/trnRequest/${response.data.token}`,
                          `_self`
                        );
                      } else {
                        window.open(
                          `${process.env.REACT_APP_P24_STAGING_ADDRESS}/trnRequest/${response.data.token}`,
                          `_self`
                        );
                      }
                    }
                  }}
                >
                  {pendingRequest && <CircularProgress size={20} />}
                  {!pendingRequest && `PŁATNOŚĆ`}
                </Button>
              )}
            </CardActions>
          </Card>
        </Grid>
      )}
    </Grid>
  ) : (
    <div></div>
  );
};

export default OrderSummary;
