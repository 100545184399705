import React, { useEffect, useState } from "react";
import {
  alertAdd,
  fetchTerms,
  register_organization,
} from "../../redux/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import ReCAPTCHA from "react-google-recaptcha";
import LeftComponentMui from "../../left-component-mui";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";
import { LockOpen, Visibility } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import s from "./index.module.css";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const OrangeButton = styled(Button)`
  background-color: orange;
  color: white;
`;

const registerStatusLabels = [
  {
    value: "PERSONAL",
    label: "Konto indywidualne",
    description: "Opis konta indywidualnego...",
  },
  {
    value: "ORGANIZATION",
    label: "Organizacja",
    description: "Opis organizacji...",
  },
];

const RegisterOrganisation = ({
  creatingOrganization,
  joiningComplete,
  joinedOrganization,
  joiningOrganization,
  history,
}) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [orgName, setOrgName] = useState("");
  const [mail, setMail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [formIsValid, setFormIsValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [recaptchaValid, setRecaptchaValid] = useState(false);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordMissingElements, setPasswordMissingElements] = useState([]);
  const [typingTimer, setTypingTimer] = useState(null);
  const [termList, setTermList] = useState([]);
  const [allTermsAccepted, setAllTermsAccepted] = useState(false);

  const [isRegisterInProgress, setIsRegisterInProgress] = useState(false);
  const [registerCompleted, setRegisterCompleted] = useState(false);
  const [registerStatus, setRegisterStatus] = useState("PERSONAL");

  const [invalidPasswordInputChar, setInvalidPasswordInputChar] = useState(
    false
  );
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);

  const dispatch = useDispatch();

  const terms = useSelector((s) => s.terms);

  useEffect(() => {
    if (termList.length === 0) {
      dispatch(fetchTerms());
    }

    setAllTermsAccepted(
      termList.reduce((all, current) => all && current.accepted, true)
    );
  }, [termList]);

  useEffect(() => {
    if (mail) {
      let polishChars1 = String(mail)
        .toLowerCase()
        .normalize("NFD")
        .search(/[\u0300-\u036f]/g);
      let polishChars2 = String(mail)
        .toLowerCase()
        .normalize("NFD")
        .search(/\u0142/g);

      setEmailValid(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          String(mail).toLowerCase()
        ) &&
          (polishChars2 !== 0
            ? polishChars1 + polishChars2 < 0
            : polishChars1 + polishChars2 < -1)
      );
    }
  }, [mail]);

  useEffect(() => {
    if (terms.length > 0) {
      setTermList(
        terms.map((t) => ({
          ...t,
          accepted: false,
        }))
      );
    }
  }, [terms]);

  const submit = async (e) => {
    e.preventDefault();

    if (formIsValid) {
      const errorHandler = (e) => {
        let errorMessage;
        setIsRegisterInProgress(false);
        if (e.response.status === 409) {
          if (
            e.response.data.message ===
            "Organization name is not unique. Please change the name."
          ) {
            errorMessage = "Nazwa organizacji jest już używana w systemie.";
          } else {
            errorMessage =
              "Błąd rejestracji, email jest już używany w systemie.";
          }
        } else {
          errorMessage = `Błąd rejestracji, (${e.message}).`;
        }

        dispatch(
          alertAdd({
            text: errorMessage,
          })
        );
      };

      try {
        const domain = window.location.hostname;

        const proto = window.location.protocol === "https:" ? "wss" : "ws";
        const port = window.location.port ? ":" + window.location.port : "";

        const ws = new WebSocket(
          `${proto}://${domain}${port}/tenants/v1/ws/registration?email=${mail.toLowerCase()}`
        );

        ws.onopen = async function (e) {
          setIsRegisterInProgress(true);
          let payload = {};
          registerStatus === "PERSONAL"
            ? (payload = {
                firstName: name,
                surname,
                mail: mail.toLowerCase(),
                password,
                accountType: registerStatus,
              })
            : (payload = {
                orgName,
                mail: mail.toLowerCase(),
                password,
                accountType: registerStatus,
              });

          try {
            await dispatch(register_organization(payload));
          } catch (e) {
            errorHandler(e);
          }
        };
        ws.onmessage = function (e) {
          setIsRegisterInProgress(
            e.data.includes("TenantCreatedWsPayload") && false
          );
          setRegisterCompleted(e.data.includes("TenantCreatedWsPayload"));
        };
      } catch (e) {
        errorHandler(e);
      }
    }
  };

  const cancel = (e) => {
    e.preventDefault();

    history.push("/login");
  };

  useEffect(() => {
    if (registerStatus === "PERSONAL") {
      setFormIsValid(
        name &&
          surname &&
          mail &&
          password &&
          passwordsMatch &&
          recaptchaValid &&
          allTermsAccepted &&
          passwordScore > 4
      );
    } else if (registerStatus === "ORGANIZATION") {
      setFormIsValid(
        orgName &&
          mail &&
          password &&
          passwordsMatch &&
          recaptchaValid &&
          allTermsAccepted &&
          passwordScore > 4
      );
    }
  }, [
    orgName,
    name,
    surname,
    mail,
    passwordsMatch,
    password,
    recaptchaValid,
    allTermsAccepted,
    passwordScore,
    registerStatus,
  ]);

  useEffect(() => {
    setPasswordsMatch(password === password2);
  }, [password, password2]);

  useEffect(() => {
    if (registerCompleted) {
      dispatch(
        alertAdd({
          text: "Rejestracja przebiegła pomyślnie.",
        })
      );
      history.push("/login");
    }
  }, [registerCompleted]);

  useEffect(() => {
    if (joiningComplete && isRegisterInProgress) {
      dispatch(
        alertAdd({
          text: "Rejestracja przebiegła pomyślnie.",
        })
      );
      history.push("/login");
    }
  }, [joiningComplete, history]);

  useEffect(() => {
    joinedOrganization === false &&
      dispatch(
        alertAdd({
          text: "Dołączenie do organizacji nie powiodło się.",
        })
      );
  }, [joinedOrganization]);

  const onChange = (value) => {
    setRecaptchaValid(!!value);
  };

  useEffect(() => {
    clearTimeout(typingTimer);
  }, []);

  const passwordMissingElementFilter = (
    tmpPasswordMissingElements,
    element
  ) => {
    return tmpPasswordMissingElements.filter(
      (missingElement) => missingElement !== element
    );
  };

  const testStrengthPassword = (e) => {
    let pass = e.target.value;
    let tmpPasswordScore = 0;
    let tmpPasswordMissingElements = [
      "Przynajmniej jedną małą literę",
      "Przynajmniej jedną wielką literę",
      "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^",
      "Przynajmniej jedeną cyfrę",
      "Długość znaków między 8, a 32.",
    ];
    if (pass.length > 7 && pass.length < 33) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Długość znaków między 8, a 32."
      );
    }
    if (/[a-z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną małą literę"
      );
    }
    if (/[A-Z]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedną wielką literę"
      );
    }
    if (/[0-9]/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jedeną cyfrę"
      );
    }
    if (/[!@#$%^&*+=_]+/.test(pass)) {
      tmpPasswordScore = tmpPasswordScore + 1;
      tmpPasswordMissingElements = passwordMissingElementFilter(
        tmpPasswordMissingElements,
        "Przynajmniej jeden znak specjalny: ! @ # $ % & * _ + = ^"
      );
    }

    clearTimeout(typingTimer);
    if (tmpPasswordScore === 0) {
      setPasswordScore(tmpPasswordScore);
    }

    setPasswordValid(tmpPasswordScore === 5);

    setTypingTimer(
      setTimeout(() => {
        if (tmpPasswordScore) {
          setPasswordScore(tmpPasswordScore);
          setPasswordMissingElements(tmpPasswordMissingElements);
        }
      }, 1000)
    );
  };

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Grid container direction={smallScreen && "column-reverse"}>
      <Grid item sm={12} xs={12} md={8}>
        <LeftComponentMui />
      </Grid>

      <Grid item sm={12} xs={12} md={4}>
        <Box p={1}>
          <Box textAlign="center">
            <LockOpen />
          </Box>
          {
            <Loader
              loading={creatingOrganization || isRegisterInProgress}
              text={
                registerStatus === "PERSONAL"
                  ? "Tworzenie konta indywidualnego"
                  : "Tworzenie organizacji"
              }
            />
          }

          {!isRegisterInProgress && (
            <>
              <Box as="h1" fontSize={20} textAlign="center">
                Załóż
                {registerStatus === "create" && (
                  <Box mt={1} as="h1" fontSize={15} textAlign="left">
                    Rejestrujesz się jako administrator oraz jednocześnie
                    zakładasz własną{" "}
                    <Tooltip title="W ramach organizacji, możesz budować formularze, zarządzać grupami użytkowników, udostępniając im formularze oraz przeglądać wyniki i eksportować je do własnych systemów.">
                      <a>organizację</a>
                    </Tooltip>
                    .
                  </Box>
                )}
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      value={registerStatus || "create"}
                      name="customized-radios"
                    >
                      {registerStatusLabels.map((status, index) => {
                        return (
                          <Tooltip title={status.description}>
                            <FormControlLabel
                              key={index}
                              value={status.value}
                              label={status.label}
                              control={<Radio color={"primary"} />}
                              onChange={(event) =>
                                setRegisterStatus(event.currentTarget.value)
                              }
                            />
                          </Tooltip>
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
              <form onSubmit={submit} noValidate autoComplete="off">
                <Box mt={1} display="flex">
                  {registerStatus === "PERSONAL" ? (
                    <>
                      <TextField
                        label="Imię"
                        variant="outlined"
                        value={name}
                        fullWidth
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                      <TextField
                        label="Nazwisko"
                        variant="outlined"
                        value={surname}
                        fullWidth
                        required
                        onChange={(e) => setSurname(e.target.value)}
                      />
                    </>
                  ) : (
                    <TextField
                      label="Nazwa organizacji"
                      variant="outlined"
                      value={orgName}
                      fullWidth
                      required
                      onChange={(e) => setOrgName(e.target.value)}
                    />
                  )}
                </Box>
                <Box mt={1}>
                  <TextField
                    label={"Email"}
                    variant="outlined"
                    value={mail}
                    error={mail && !emailValid}
                    helperText={
                      mail &&
                      !emailValid &&
                      "Nieprawidłowy format wprowadzonego adresu email"
                    }
                    required
                    fullWidth
                    onChange={(e) => setMail(e.target.value)}
                  />
                </Box>
                <Box mt={1} display="flex">
                  <TextField
                    label="Hasło"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    error={
                      invalidPasswordInputChar || (!passwordValid && password)
                    }
                    helperText={
                      invalidPasswordInputChar
                        ? "Spacja nie jest dozwolona."
                        : !passwordValid && password
                        ? "Hasło nie spełnia wymogów"
                        : ""
                    }
                    required
                    onChange={(e) => {
                      if (e.target.value.includes(" ")) {
                        setInvalidPasswordInputChar(true);
                        setTimeout(
                          () => setInvalidPasswordInputChar(false),
                          3000
                        );
                      } else {
                        setInvalidPasswordInputChar(false);
                      }
                      setPassword(e.target.value.split(" ").join(""));
                      testStrengthPassword(e);
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            aria-label="toggle password visibility"
                          >
                            <Visibility />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    label="Powtórz hasło"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    value={password2}
                    required
                    error={password2 && !passwordsMatch}
                    helperText={
                      password2 &&
                      !passwordsMatch &&
                      "Hasła nie pasują do siebie"
                    }
                    onChange={(e) =>
                      setPassword2(e.target.value.split(" ").join(""))
                    }
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            aria-label="toggle password visibility"
                          >
                            <Visibility />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box mt={1}>
                  <span className={s.formHint}>* Pola wymagane</span>
                </Box>
                <Box mt={1} display="flex">
                  <span className={s.formHint}>
                    Aby spełnić zalożenie polityki Silnego Hasła prosi się o
                    podanie co najmniej jednej wielkiej litery, małej litery,
                    cyfry oraz znaku specjanlego. Hasło powinno być dłuższe niż
                    7 znaków.
                  </span>
                </Box>
                {passwordScore > 0 && (
                  <div>
                    <label className={s.labelStrengthPassword}>
                      Siła hasła:
                    </label>
                    <Box mt={1} display="flex">
                      <span
                        className={s.strengthPassword}
                        dataScore={passwordScore}
                      />
                    </Box>
                    {passwordMissingElements.length > 0 && (
                      <label
                        className={s.labelStrengthPassword}
                        style={{ marginTop: "10px", color: "#ef5350" }}
                      >
                        Aby hasło było silne, należy zawrzeć:
                      </label>
                    )}
                    <span className={s.errorFormHint}>
                      {passwordMissingElements.map((el, index) => {
                        return <li key={index}>{el}</li>;
                      })}
                    </span>
                  </div>
                )}

                <Box mt={1}>
                  {termList && termList.length > 0 && (
                    <label>Akceptuję następujące regulaminy: </label>
                  )}{" "}
                </Box>

                <Box>
                  {termList &&
                    termList.length > 0 &&
                    termList.map((term) => {
                      return (
                        <div key={term.id}>
                          <input
                            type="checkbox"
                            checked={term.accepted}
                            onChange={() => {
                              setTermList(
                                termList.map((acceptedTerm) =>
                                  acceptedTerm.id === term.id
                                    ? {
                                        ...acceptedTerm,
                                        accepted: !acceptedTerm.accepted,
                                      }
                                    : acceptedTerm
                                )
                              );
                            }}
                            style={{ marginRight: "5px", marginLeft: "10px" }}
                          />
                          <span>
                            <label style={{ marginRight: "8px" }}>
                              {term.name}
                            </label>
                            <a
                              href={`/org-media/files${term.link}`}
                              target={"_blank"}
                            >
                              przeczytaj
                            </a>
                          </span>
                        </div>
                      );
                    })}{" "}
                </Box>

                <Box mt={1}>
                  <ReCAPTCHA
                    sitekey="6Lev2u4UAAAAAE0emXI5hh5W-VfQWagdCiqBpXRu"
                    onChange={onChange}
                  />
                </Box>
                <Box mt={1} display="flex">
                  <Button variant={"contained"} fullWidth onClick={cancel}>
                    Anuluj
                  </Button>
                  <OrangeButton
                    type="submit"
                    variant={"contained"}
                    fullWidth
                    disabled={!formIsValid || (mail && !emailValid)}
                  >
                    Zarejestruj się
                  </OrangeButton>
                </Box>
              </form>
            </>
          )}
          <Box position="fixed" bottom={20} right={20}>
            {/*//TODO remove when pdfs will be ready */}
            <a
              href="/org-media/files/public/instrukcje-user.pdf"
              target="_blank"
            >
              <Button
                variant="contained"
                color="primary"
                style={{ float: "right" }}
              >
                POMOC
              </Button>
            </a>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  creatingOrganization: state.creatingOrganization,
  joiningOrganization: state.joiningOrganization,
  joinedOrganization: state.joinedOrganization,
  joiningComplete: state.joiningComplete,
});

export default connect(mapStateToProps)(RegisterOrganisation);
