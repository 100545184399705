import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Link from "@material-ui/core/Link";
import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import MuiIconButton from "@material-ui/core/IconButton";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import SvgIcon from "@material-ui/core/SvgIcon";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import ChatBubbleOutlineOutlinedIcon from "@material-ui/icons/ChatBubbleOutlineOutlined";
import { ReactComponent as SmellIcon } from "../../assets/weather-windy.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLemon,
  faWind,
  faHouseUser,
  faEnvelope,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";
import { faLemon as faLemonRegular } from "@fortawesome/free-regular-svg-icons";
// import { faWind as faWindRegular } from "@fortawesome/free-regular-svg-icons";
import { faHome as faHomeRegular } from "@fortawesome/free-regular-svg-icons";
import { faEnvelope as faEnvelopeRegular } from "@fortawesome/free-regular-svg-icons";
import { faCommentAlt as faCommentAltRegular } from "@fortawesome/free-regular-svg-icons";
import { fetchCartItems } from "../../redux/shopping-cart/actions";
import { setFontSize, toggleGlobalTheme } from "../../redux/actions";
import Badge from "@material-ui/core/Badge";
import s from "./index.module.css";
import image1 from "../../assets/logo_smak.jpg";
// import image11 from "../../assets/logo_smak_resized.jpg";
import image11 from "../../assets/logo_smak.svg";
import image111 from "../../assets/logo_smak_mobile.png";
import clsx from "clsx";
import { ReactComponent as ShoppinCartIcon } from "../../assets/shopping-cart.svg";
import imageShoppingCart from "../../assets/shopping-cart-orange.jpg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ContrastIcon from "@material-ui/icons/BrightnessMedium";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import withStyles from "@material-ui/core/styles/withStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuLabel: {
    color: "white",
    fontSize: theme.typography.leftMenuLabel,

    marginRight: 40,
    marginTop: 10,
  },
  menuLabelLeft: {
    color: "black",
    fontSize: theme.typography.leftMenuLabel,

    marginRight: 30,
    "&:hover": {
      color: "orange",
    },
  },
  paper: {
    borderTopRightRadius: "30px",
  },
  icon: {
    color: "white",
    "&:hover": {
      color: "orange",
    },
  },
  mainContent: {
    flexGrow: 1,
    padding: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(11),
      minHeight: `calc(100vh - ${theme.spacing(11)}px)`,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(11),
      minHeight: `calc(100vh - ${theme.spacing(10)}px)`,
    },
  },
  headerLabel: {
    fontStyle: "normal",
    fontVariant: "normal",
    fontWeight: "400",
    fontSize: theme.typography.leftMenuLabel,
    letterSpacing: "0px",
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    "&:hover": {
      textDecoration: `underline ${theme.palette.text.alternative}`,
      textUnderlineOffset: "2px",
      fontWeight: "400",
      color: theme.palette.text.primary,
    },
  },
  headerLabelActive: {
    color: theme.palette.text.alternative,
    textDecoration: `underline ${theme.palette.text.alternative} 3.5px`,
    textUnderlineOffset: theme.typography.underline,
  },

  headerLabelRight: {
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    "&:hover": {
      textDecoration: `underline ${theme.palette.text.alternative}`,
      textUnderlineOffset: "2px",
      fontWeight: "400",
      color: theme.palette.text.primary,
    },
  },

  headerLabelRightIcon: {
    "&:hover": {
      borderBottom: `1.5px solid ${theme.palette.text.alternative}`,
    },
  },
  headerLabelActiveRight: {
    color: theme.palette.text.alternative,
    textDecoration: `underline ${theme.palette.text.alternative} ${theme.typography.underline}`,
    textUnderlineOffset: "16px",
    "-webkit-text-decoration-line": "underline !important",
    "-webkit-text-decoration-color": `${theme.palette.text.alternative} !important`,
    "-webkit-text-decoration-style": "solid !important",
    "text-decoration-thickness": "4px",
  },

  headerMobileLabel: {
    color: theme.palette.text.primary,
    textTransform: "uppercase",
  },
  headerLabelMobileActive: {
    fontWeight: 500,
    color: theme.palette.text.alternative,
  },
}));

const IconButton = withStyles({
  root: {
    padding: "4px",
    marginTop: "10px",
    height: "25px",
    borderRadius: "0%",
    marginRight: "10px",
  },
})(MuiIconButton);
const HeaderMenu = ({ cartItems, children }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();

  const xSmall = useMediaQuery((theme) => theme.breakpoints.down(440));
  const mobileLogo = useMediaQuery((theme) => theme.breakpoints.down(600));
  const large = useMediaQuery((theme) => theme.breakpoints.down(1280));
  const menuMargin = useMediaQuery((theme) => theme.breakpoints.up(1045));
  const imageDisplay = useMediaQuery((theme) => theme.breakpoints.down(1920));

  const fontSize = useSelector((s) => s.fontSize || "normal");

  useEffect(() => {
    dispatch(fetchCartItems());
  }, []);

  const navigationLinks = [
    {
      name: ["start"],
      icon: <FontAwesomeIcon icon={faHouseUser} />,
      label: "Jak to działa?",
      href: "/start",
      newTab: false,
      fontWeight: "600",
    },
    {
      name: ["taste"],
      icon: <FontAwesomeIcon icon={faLemonRegular} />,
      label: "Smak",
      href: "https://testsmakuiwechu.pl/home/o-smaku-i-wechu-o-wechu-2/",
      newTab: true,
    },
    {
      name: ["smell"],
      icon: <FontAwesomeIcon icon={faWind} />,
      label: "Węch",
      href: "https://testsmakuiwechu.pl/home/o-smaku-i-wechu-o-wechu/",
      newTab: true,
    },
    {
      name: ["contact"],
      icon: <FontAwesomeIcon icon={faEnvelopeRegular} />,
      label: "Kontakt",
      href: "/contact",
      newTab: false,
    },
    {
      name: ["about_us"],
      icon: <FontAwesomeIcon icon={faCommentAltRegular} />,
      label: "O nas",
      href: "https://testsmakuiwechu.pl/demo/o-nas/",
      newTab: true,
    },
  ];

  const navigationRightLinks = [
    {
      name: "order-test-kit",
      label: (active) => {
        if (active) {
          return (
            <Badge
              badgeContent={cartItems?.length}
              color="secondary"
              invisible={cartItems?.length === 0}
            >
              <img
                alt="Ikonka koszyka. Zawartość:"
                src={imageShoppingCart}
                style={{ height: "27px" }}
              >
                {/* <span>TEst 123</span> */}
              </img>
            </Badge>
          );
        } else {
          return (
            <Badge
              badgeContent={cartItems?.length}
              color="secondary"
              invisible={cartItems?.length === 0}
            >
              <ShoppinCartIcon
                role="img"
                aria-hidden="false"
                alt="Ikonka koszyka. Zawartość:"
                title="Ikonka koszyka. Zawartość:"
                style={{ height: "25px" }}
              />
            </Badge>
          );
        }
      },
      href: "/order-test-kit",
    },
    { name: "login", label: "Zaloguj", href: "/login" },
    {
      name: "register",
      label: "Załóż konto",
      href: "/register",
      fontWeight: "600",
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <AppBar
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "#102655",
            backgroundColor: theme.drawer.backgroundColor,
            borderBottom: `2px solid ${theme.palette.text.alternative}`,
            boxShadow: "none",
          }}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1px",
            }}
          >
            <Tooltip title="Przełącz kontrastowy tryb strony">
              <IconButton>
                <ContrastIcon onClick={() => dispatch(toggleGlobalTheme())} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Zmień rozmiar czcionki">
              <IconButton>
                <TextFormatIcon
                  style={{ border: fontSize === "normal" && "1px solid" }}
                  fontSize="small"
                  onClick={() => dispatch(setFontSize("normal"))}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Zmień rozmiar czcionki">
              <IconButton>
                <TextFormatIcon
                  style={{ border: fontSize === "medium" && "1px solid" }}
                  fontSize="medium"
                  onClick={() => dispatch(setFontSize("medium"))}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Zmień rozmiar czcionki">
              <IconButton>
                <TextFormatIcon
                  style={{ border: fontSize === "large" && "1px solid" }}
                  fontSize="large"
                  onClick={() => dispatch(setFontSize("large"))}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Hidden smDown>
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: imageDisplay ? "center" : "flex-end",
              }}
            >
              <Grid item container sm={12} lg={11}>
                <Grid item xs={9} style={{ display: "flex" }}>
                  <ToolBar style={{ paddingLeft: "0px", marginLeft: "0px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        alt="Logotyp Test Smaku i Węchu"
                        tabindex="1"
                        role="button"
                        onClick={() => {
                          history.push("/");
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode == 13) history.push("/");
                        }}
                        src={image11}
                        style={{
                          cursor: "pointer",
                          marginRight: "15px",
                          height: "60px",
                        }}
                      />
                    </div>
                    {navigationLinks.map((link, id) => (
                      <a
                        style={{ marginLeft: menuMargin && "20px" }}
                        tabindex={1 + id}
                        key={id}
                        href={link.href}
                        className={`${classes.headerLabel} ${
                          location.pathname.match(`^/${link.name}`) &&
                          classes.headerLabelActive
                        }`}
                        target={link.newTab ? "_blank" : ""}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                            marginRight: "12px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: theme.typography.text,
                              whiteSpace: "nowrap",
                              fontWeight: link.fontWeight && link.fontWeight,
                            }}
                          >
                            {link.label}
                          </span>
                        </div>
                      </a>
                    ))}
                  </ToolBar>
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <ToolBar style={{ marginRight: "0px", paddingRight: "0px" }}>
                    {navigationRightLinks.map((link, id) =>
                      link.name !== "order-test-kit" ? (
                        <a
                          tabindex={navigationLinks.length + id}
                          key={id}
                          target={link.newTab ? "_blank" : ""}
                          onClick={() => {
                            history.push({
                              pathname: link.href,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode == 13)
                              history.push({
                                pathname: link.href,
                              });
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              marginRight: "20px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: theme.typography.text,
                                whiteSpace: "nowrap",
                                fontWeight: link.fontWeight && link.fontWeight,
                              }}
                              className={`${classes.headerLabelRight} ${
                                location.pathname.match(`^/${link.name}`) &&
                                classes.headerLabelActiveRight
                              }`}
                            >
                              {link.label}
                            </span>
                          </div>
                        </a>
                      ) : (
                        <a
                          tabindex={navigationLinks.length + id}
                          key={id}
                          target={link.newTab ? "_blank" : ""}
                          onClick={() => {
                            history.push({
                              pathname: link.href,
                            });
                          }}
                          onKeyDown={(e) => {
                            if (e.keyCode == 13)
                              history.push({
                                pathname: link.href,
                              });
                          }}
                          style={{
                            height: "63px",
                            display: "flex",
                            alignItems: "center",
                            borderBottom:
                              location.pathname.match(`^/${link.name}`) &&
                              `3.5px solid ${theme.palette.text.alternative}`,
                          }}
                        >
                          <div
                            className={classes.headerLabelRightIcon}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "10px",
                              marginRight: "20px",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                fontSize: theme.typography.text,
                                width: "30px",
                                whiteSpace: "nowrap",
                                fontWeight: link.fontWeight && link.fontWeight,
                              }}
                              className={`${classes.headerLabelRight} ${
                                location.pathname.match(`^/${link.name}`) &&
                                classes.headerLabelActiveRight
                              }`}
                            >
                              {link.label(
                                location.pathname.match(`^/${link.name}`)
                              )}
                            </span>
                          </div>
                        </a>
                      )
                    )}
                  </ToolBar>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid
              container
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Grid item container sm={12} md={10}>
                <Grid item xs={2} sm={4}>
                  <ToolBar
                    style={{
                      display: "flex",
                      alignItems: "flex-end",
                      paddingLeft: mobileLogo && "10px",
                    }}
                  >
                    <IconButton style={{ padding: mobileLogo && "16px 0px" }}>
                      <MenuIcon
                        style={{ color: theme.palette.text.alternative2 }}
                        onClick={() => setOpen(true)}
                      />
                    </IconButton>
                    <img
                      alt="Logotyp Test Smaku i Węchu"
                      onClick={() => history.push("/")}
                      src={mobileLogo ? image111 : image1}
                      style={{ height: 55, cursor: "pointer" }}
                    />
                  </ToolBar>
                </Grid>
                <Grid
                  item
                  xs={10}
                  sm={8}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {navigationRightLinks.map((link, id) =>
                    link.name !== "order-test-kit" ? (
                      <a
                        key={id}
                        href={link.href}
                        target={link.newTab ? "_blank" : ""}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                            marginRight: "20px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <span
                            style={{
                              fontSize: xSmall
                                ? theme.typography.footer
                                : theme.typography.text,
                            }}
                            className={`${classes.headerLabelRight} ${
                              location.pathname.match(`^/${link.name}`) &&
                              classes.headerLabelActiveRight
                            }`}
                          >
                            {link.label}
                          </span>
                        </div>
                      </a>
                    ) : (
                      <a
                        key={id}
                        href={link.href}
                        target={link.newTab ? "_blank" : ""}
                        style={{
                          height: "63px",
                          display: "flex",
                          alignItems: "center",
                          borderBottom:
                            location.pathname.match(`^/${link.name}`) &&
                            `4px solid ${theme.palette.text.alternative}`,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "10px",
                            marginRight: "20px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <span
                            style={{
                              fontSize: xSmall
                                ? theme.typography.footer
                                : theme.typography.text,
                            }}
                            className={`${classes.headerLabelRight} ${
                              location.pathname.match(`^/${link.name}`) &&
                              classes.headerLabelActiveRight
                            }`}
                          >
                            {link.label(
                              location.pathname.match(`^/${link.name}`)
                            )}
                          </span>
                        </div>
                      </a>
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <SwipeableDrawer
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            classes={{
              paper: classes.paper,
            }}
          >
            <div>
              <IconButton>
                <ChevronLeftIcon onClick={() => setOpen(false)} />
              </IconButton>
            </div>
            <List>
              {navigationLinks.map((link, id) => (
                <ListItem key={id}>
                  <Link
                    color="textPrimary"
                    variant="button"
                    href={link.href}
                    className={classes.menuLabelLeft}
                    target={link.newTab ? "_blank" : ""}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <span
                        className={clsx(classes.headerMobileLabel, {
                          [classes.headerLabelMobileActive]:
                            location.pathname.length === 1 &&
                            link.name[0] === "start"
                              ? true
                              : location.pathname.match(`^/${link.name[0]}`)
                              ? location.pathname.match(`^/${link.name[0]}`)
                              : location.pathname.match(`^/${link.name[1]}`),
                        })}
                      >
                        {link.label}
                      </span>
                    </div>
                  </Link>
                </ListItem>
              ))}
            </List>
          </SwipeableDrawer>
        </AppBar>
      </Grid>
      <Grid item className={classes.mainContent} xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};
export default connect(
  (state) => ({
    cartItems: state.cartItems,
  }),
  (dispatch) => ({})
)(HeaderMenu);
